export const AUTH_FAIL_NO_TOKEN = "AUTH_FAIL_NO_TOKEN";

export const LOGIN_START = "LOGIN_START";
// Login with password successful.
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
// Login with both password and if needed, 2FA, complete.
export const LOGIN_COMPLETE = "LOGIN_COMPLETE";

export const LOGOUT_START = "LOGOUT_START";

export const FORGOT_PASSWORD_START = "FORGOT_PASSWORD_START";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";

export const RESET_PASSWORD_START = "RESET_PASSWORD_START";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";

export const ACTIVATE_INVITE_START = "ACTIVATE_INVITE_START";
export const ACTIVATE_INVITE_SUCCESS = "ACTIVATE_INVITE_SUCCESS";
export const ACTIVATE_INVITE_FAIL = "ACTIVATE_INVITE_FAIL";

export const SAVE_MENU_FILTER_SETTINGS_START =
  "SAVE_MENU_FILTER_SETTINGS_START";
export const SAVE_MENU_FILTER_SETTINGS_SUCCESS =
  "SAVE_MENU_FILTER_SETTINGS_SUCCESS";
export const SAVE_MENU_FILTER_SETTINGS_FAIL = "SAVE_MENU_FILTER_SETTINGS_FAIL";

export const CLEAR_LOGIN_ERRORS = "CLEAR_LOGIN_ERRORS";

export const SHOW_SUBMENU = "SHOW_SUBMENU";
export const HIDE_SUBMENU = "HIDE_SUBMENU";

export const SELECT_FILTER = "SELECT_FILTER";
export const SELECT_SINGLE_FILTER = "SELECT_SINGLE_FILTER";
export const RESET_FILTERS = "RESET_FILTERS";

export const SET_FILTER = "SET_FILTER";
export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";

export const SET_BOOLEAN_FILTER = "SET_BOOLEAN_FILTER";

export const SET_DATE_FILTERS = "SET_DATE_FILTERS";
export const SET_DATE_TERM = "SET_DATE_TERM";

export const EXECUTE_QUERY_START = "EXECUTE_QUERY_START";
export const EXECUTE_QUERY_SUCCESS = "EXECUTE_QUERY_SUCCESS";
export const EXECUTE_QUERY_FAIL = "EXECUTE_QUERY_FAIL";

export const MARK_BLOCKS_REFRESHING = "MARK_BLOCKS_REFRESHING";

export const LOAD_LANDING_SITE = "LOAD_LANDING_SITE";

export const LOAD_DASHBOARD_CONFIGURATION_START =
  "LOAD_DASHBOARD_CONFIGURATION_START";
export const LOAD_DASHBOARD_CONFIGURATION_SUCCESS =
  "LOAD_DASHBOARD_CONFIGURATION_SUCCESS";
export const LOAD_DASHBOARD_CONFIGURATION_FAIL =
  "LOAD_DASHBOARD_CONFIGURATION_FAIL";

export const SET_CHART_VISIBILITY = "SET_CHART_VISIBILITY";

export const LOAD_DASHBOARD_FILE_CONFIGURATION_SUCCESS =
  "LOAD_DASHBOARD_FILE_CONFIGURATION_SUCCESS";

export const GET_THEME_CONFIGURATION_START = "GET_THEME_CONFIGURATION_START";
export const GET_THEME_CONFIGURATION_SUCCESS =
  "GET_THEME_CONFIGURATION_SUCCESS";
export const GET_THEME_CONFIGURATION_FAIL = "GET_THEME_CONFIGURATION_FAIL";

export const CHANGE_THEME_CONFIGURATION_START =
  "CHANGE_THEME_CONFIGURATION_START";
export const CHANGE_THEME_CONFIGURATION_SUCCESS =
  "CHANGE_THEME_CONFIGURATION_SUCCESS";
export const CHANGE_THEME_CONFIGURATION_FAIL =
  "CHANGE_THEME_CONFIGURATION_FAIL";

export const SHOW_ALL_TABLE_RECORDS_START = "SHOW_ALL_TABLE_RECORDS_START";
export const SHOW_ALL_TABLE_RECORDS_SUCCESS = "SHOW_ALL_TABLE_RECORDS_SUCCESS";
export const SHOW_ALL_TABLE_RECORDS_FAIL = "SHOW_ALL_TABLE_RECORDS_FAIL";
export const CLEAR_ALL_TABLE_RECORDS = "CLEAR_ALL_TABLE_RECORDS";

export const LOAD_HEADER_CONFIG_START = "LOAD_HEADER_CONFIG_START";
export const LOAD_HEADER_CONFIG_SUCCESS = "LOAD_HEADER_CONFIG_SUCCESS";
export const LOAD_HEADER_CONFIG_FAIL = "LOAD_HEADER_CONFIG_FAIL";

export const CACHE_MENU_FILTER_CHECKED_VALUES =
  "CACHE_MENU_FILTER_CHECKED_VALUES";

export const LOAD_MENU_FILTER_VALUES_START = "LOAD_MENU_FILTER_VALUES_START";
export const LOAD_MENU_FILTER_VALUES_SUCCESS =
  "LOAD_MENU_FILTER_VALUES_SUCCESS";
export const LOAD_MENU_FILTER_VALUES_FAIL = "LOAD_MENU_FILTER_VALUES_FAIL";
export const RESET_MENU_FILTER_VALUES = "RESET_MENU_FILTER_VALUES";

export const CLEAR_BLOCKS = "CLEAR_BLOCKS";

export const SET_LOAD_BUNDLE = "SET_LOAD_BUNDLE";
export const SET_LOAD_HEADER_BUNDLE = "SET_LOAD_HEADER_BUNDLE";

export const GET_USER_LIST_START = "GET_USER_LIST_START";
export const GET_USER_LIST_SUCCESS = "GET_USER_LIST_SUCCESS";
export const GET_USER_LIST_FAIL = "GET_USER_LIST_FAIL";

export const CREATE_USER_START = "CREATE_USER_START";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_FAIL = "CREATE_USER_FAIL";

export const DELETE_USER_START = "DELETE_USER_START";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";

export const UPDATE_USER_START = "UPDATE_USER_START";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";

export const DOWNLOAD_TABLE_DATA_START = "DOWNLOAD_TABLE_DATA_START";
export const DOWNLOAD_TABLE_DATA_SUCCESS = "DOWNLOAD_TABLE_DATA_SUCCESS";
export const DOWNLOAD_TABLE_DATA_FAIL = "DOWNLOAD_TABLE_DATA_FAIL";

export const HIDE_HEADER = "HIDE_HEADER";
export const SHOW_HEADER = "SHOW_HEADER";

export const LIST_QUERYABLES_START = "LIST_QUERYABLES_START";
export const LIST_QUERYABLES_SUCCESS = "LIST_QUERYABLES_SUCCESS";
export const LIST_QUERYABLES_FAIL = "LIST_QUERYABLES_FAIL";

export const GET_QUERYABLE_START = "GET_QUERYABLE_START";
export const GET_QUERYABLE_SUCCESS = "GET_QUERYABLE_SUCCESS";
export const GET_QUERYABLE_FAIL = "GET_QUERYABLE_FAIL";

export const LIST_QUERIES_START = "LIST_QUERIES_START";
export const LIST_QUERIES_SUCCESS = "LIST_QUERIES_SUCCESS";
export const LIST_QUERIES_FAIL = "LIST_QUERIES_FAIL";

export const LIST_QUERIES_TINY_START = "LIST_QUERIES_TINY_START";
export const LIST_QUERIES_TINY_SUCCESS = "LIST_QUERIES_TINY_SUCCESS";
export const LIST_QUERIES_TINY_FAIL = "LIST_QUERIES_TINY_FAIL";

export const GET_QUERY_START = "GET_QUERY_START";
export const GET_QUERY_SUCCESS = "GET_QUERY_SUCCESS";
export const GET_QUERY_FAIL = "GET_QUERY_FAIL";
export const GET_QUERY_RESET = "GET_QUERY_RESET";

export const UPDATE_QUERY_START = "UPDATE_QUERY_START";
export const UPDATE_QUERY_SUCCESS = "UPDATE_QUERY_SUCCESS";
export const UPDATE_QUERY_FAIL = "UPDATE_QUERY_FAIL";

export const CREATE_QUERY_START = "CREATE_QUERY_START";
export const CREATE_QUERY_SUCCESS = "CREATE_QUERY_SUCCESS";
export const CREATE_QUERY_FAIL = "CREATE_QUERY_FAIL";

export const DELETE_QUERY_START = "DELETE_QUERY_START";
export const DELETE_QUERY_SUCCESS = "DELETE_QUERY_SUCCESS";
export const DELETE_QUERY_FAIL = "DELETE_QUERY_FAIL";

export const CLOSE_QUERYABLE = "CLOSE_QUERYABLE";

export const UPLOAD_ETL_FILE_INIT = "UPLOAD_ETL_FILE_INIT";
export const UPLOAD_ETL_FILE_START = "UPLOAD_ETL_FILE_START";
export const UPLOAD_ETL_FILE_SUCCESS = "UPLOAD_ETL_FILE_SUCCESS";
export const UPLOAD_ETL_FILE_FAIL = "UPLOAD_ETL_FILE_FAIL";
export const RESET_ETL_FILE_UPLOAD = "RESET_ETL_FILE_UPLOAD";

export const GET_ETL_PROCESSINGS_START = "GET_ETL_PROCESSINGS_START";
export const GET_ETL_PROCESSINGS_SUCCESS = "GET_ETL_PROCESSINGS_SUCCESS";
export const GET_ETL_PROCESSINGS_FAIL = "GET_ETL_PROCESSINGS_FAIL";

export const ETL_AUTODETECT_INIT = "ETL_AUTODETECT_INIT";
export const ETL_AUTODETECT_START = "ETL_AUTODETECT_START";
export const ETL_AUTODETECT_SUCCESS = "ETL_AUTODETECT_SUCCESS";
export const ETL_AUTODETECT_FAIL = "ETL_AUTODETECT_FAIL";

export const UPDATE_ETL_PROCESSING = "UPDATE_ETL_PROCESSING";
export const CREATE_ETL_PROCESSING = "CREATE_ETL_PROCESSING";

export const RELOAD_ETL_PROCESSINGS_START = "RELOAD_ETL_PROCESSINGS_START";
export const RELOAD_ETL_PROCESSINGS_SUCCESS = "RELOAD_ETL_PROCESSINGS_SUCCESS";
export const RELOAD_ETL_PROCESSINGS_FAIL = "RELOAD_ETL_PROCESSINGS_FAIL";

export const GET_DATA_FLOW_START = "GET_DATA_FLOW_START";
export const GET_DATA_FLOW_SUCCESS = "GET_DATA_FLOW_SUCCESS";
export const GET_DATA_FLOW_FAIL = "GET_DATA_FLOW_FAIL";

export const SET_USER_ROLE = "SET_USER_ROLE";
export const SET_USER_GROUP = "SET_USER_GROUP";
export const SET_USER_ACCOUNT = "SET_USER_ACCOUNT";

export const CLEAR_USER_FORM_SELECTS = "CLEAR_USER_FORM_SELECTS";

export const UPLOAD_PROFILE_IMAGE_START = "UPLOAD_PROFILE_IMAGE_START";
export const UPLOAD_PROFILE_IMAGE_SUCCESS = "UPLOAD_PROFILE_IMAGE_SUCCESS";
export const UPLOAD_PROFILE_IMAGE_FAIL = "UPLOAD_PROFILE_IMAGE_FAIL";

export const DELETE_PROFILE_IMAGE_SUCCESS = "DELETE_PROFILE_IMAGE_SUCCESS";
export const DELETE_PROFILE_IMAGE_FAIL = "DELETE_PROFILE_IMAGE_FAIL";

export const CLEAR_AVATAR_ON_DELETE = "CLEAR_AVATAR_ON_DELETE";

export const LOAD_USER_GROUPS_START = "LOAD_USER_GROUPS_START";
export const LOAD_USER_GROUPS_SUCCESS = "LOAD_USER_GROUPS_SUCCESS";
export const LOAD_USER_GROUPS_FAIL = "LOAD_USER_GROUPS_FAIL";

export const DELETE_USER_GROUP_START = "DELETE_USER_GROUP_START";
export const DELETE_USER_GROUP_SUCCESS = "DELETE_USER_GROUP_SUCCESS";
export const DELETE_USER_GROUP_FAIL = "DELETE_USER_GROUP_FAIL";

export const CREATE_USER_GROUP_START = "CREATE_USER_GROUP_START";
export const CREATE_USER_GROUP_SUCCESS = "CREATE_USER_GROUP_SUCCESS";
export const CREATE_USER_GROUP_FAIL = "CREATE_USER_GROUP_FAIL";

export const UPDATE_USER_GROUP_START = "UPDATE_USER_GROUP_START";
export const UPDATE_USER_GROUP_SUCCESS = "UPDATE_USER_GROUP_SUCCESS";
export const UPDATE_USER_GROUP_FAIL = "UPDATE_USER_GROUP_FAIL";

export const LOAD_USER_ACCOUNTS_START = "LOAD_USER_ACCOUNTS_START";
export const LOAD_USER_ACCOUNTS_SUCCESS = "LOAD_USER_ACCOUNTS_SUCCESS";
export const LOAD_USER_ACCOUNTS_FAIL = "LOAD_USER_ACCOUNTS_FAIL";

export const CREATE_USER_ACCOUNT_START = "CREATE_USER_ACCOUNT_START";
export const CREATE_USER_ACCOUNT_SUCCESS = "CREATE_USER_ACCOUNT_SUCCESS";
export const CREATE_USER_ACCOUNT_FAIL = "CREATE_USER_ACCOUNT_FAIL";

export const LOAD_USER_ROLES_START = "LOAD_USER_ROLES_START";
export const LOAD_USER_ROLES_SUCCESS = "LOAD_USER_ROLES_SUCCESS";
export const LOAD_USER_ROLES_FAIL = "LOAD_USER_ROLES_FAIL";

export const CREATE_USER_ROLE_START = "CREATE_USER_ROLE_START";
export const CREATE_USER_ROLE_SUCCESS = "CREATE_USER_ROLE_SUCCESS";
export const CREATE_USER_ROLE_FAIL = "CREATE_USER_ROLE_FAIL";

export const UPDATE_USER_ROLE_START = "CREATE_USER_ROLE_START";
export const UPDATE_USER_ROLE_SUCCESS = "CREATE_USER_ROLE_SUCCESS";
export const UPDATE_USER_ROLE_FAIL = "CREATE_USER_ROLE_FAIL";

export const UPDATE_USER_PASSWORD_START = "UPDATE_USER_PASSWORD_START";
export const UPDATE_USER_PASSWORD_SUCCESS = "UPDATE_USER_PASSWORD_SUCCESS";
export const UPDATE_USER_PASSWORD_FAIL = "UPDATE_USER_PASSWORD_FAIL";

export const GET_SITE_CONFIGURATION_START = "GET_SITE_CONFIGURATION_START";
export const GET_SITE_CONFIGURATION_FAIL = "GET_SITE_CONFIGURATION_FAIL";
export const GET_SITE_CONFIGURATION_SUCCESS = "GET_SITE_CONFIGURATION_SUCCESS";

export const UPDATE_SITE_CONFIGURATION_START =
  "UPDATE_SITE_CONFIGURATION_START";
export const UPDATE_SITE_CONFIGURATION_SUCCESS =
  "UPDATE_SITE_CONFIGURATION_SUCCESS";
export const UPDATE_SITE_CONFIGURATION_FAIL = "UPDATE_SITE_CONFIGURATION_FAIL";

export const GET_PUBLIC_CONFIG_START = "GET_PUBLIC_CONFIG_START";
export const GET_PUBLIC_CONFIG_SUCCESS = "GET_PUBLIC_CONFIG_SUCCESS";
export const GET_PUBLIC_CONFIG_FAIL = "GET_PUBLIC_CONFIG_FAIL";

export const CHECK_AUTH_START = "CHECK_AUTH_START";
export const CHECK_AUTH_SUCCESS = "CHECK_AUTH_SUCCESS";
export const CHECK_AUTH_FAIL = "CHECK_AUTH_FAIL";

export const UPDATE_TWO_FACTOR_AUTHENTICATION_REQUIRED =
  "UPDATE_TWO_FACTOR_AUTHENTICATION_REQUIRED";

export const ADD_TOAST = "ADD_TOAST";
export const REMOVE_TOAST = "REMOVE_TOAST";

export const GET_ATTRIBUTE_LIST_START = "GET_ATTRIBUTE_LIST_START";
export const GET_ATTRIBUTE_LIST_FAIL = "GET_ATTRIBUTE_LIST_FAIL";
export const GET_ATTRIBUTE_LIST_SUCCESS = "GET_ATTRIBUTE_LIST_SUCCESS";

export const CREATE_ATTRIBUTE_START = "CREATE_ATTRIBUTE_START";
export const CREATE_ATTRIBUTE_FAIL = "CREATE_ATTRIBUTE_FAIL";
export const CREATE_ATTRIBUTE_SUCCESS = "CREATE_ATTRIBUTE_SUCCESS";

export const UPDATE_ATTRIBUTE_START = "UPDATE_ATTRIBUTE_START";
export const UPDATE_ATTRIBUTE_FAIL = "UPDATE_ATTRIBUTE_FAIL";
export const UPDATE_ATTRIBUTE_SUCCESS = "UPDATE_ATTRIBUTE_SUCCESS";

export const DELETE_ATTRIBUTE_START = "DELETE_ATTRIBUTE_START";
export const DELETE_ATTRIBUTE_FAIL = "DELETE_ATTRIBUTE_FAIL";
export const DELETE_ATTRIBUTE_SUCCESS = "DELETE_ATTRIBUTE_SUCCESS";

export const GET_ATTRIBUTE_OPTION_START = "LOAD_ATTRIBUTE_OPTION_START";
export const GET_ATTRIBUTE_OPTION_FAIL = "LOAD_ATTRIBUTE_OPTION_FAIL";
export const GET_ATTRIBUTE_OPTION_SUCCESS = "LOAD_ATTRIBUTE_OPTION_SUCCESS";

export const CREATE_ATTRIBUTE_OPTION_START = "CREATE_ATTRIBUTE_OPTION_START";
export const CREATE_ATTRIBUTE_OPTION_FAIL = "CREATE_ATTRIBUTE_OPTION_FAIL";
export const CREATE_ATTRIBUTE_OPTION_SUCCESS =
  "CREATE_ATTRIBUTE_OPTION_SUCCESS";

export const CREATE_ATTRIBUTE_OPTION_FROM_SOURCE_AND_FIELD_START =
  "CREATE_ATTRIBUTE_OPTION_FROM_SOURCE_AND_FIELD_START";
export const CREATE_ATTRIBUTE_OPTION_FROM_SOURCE_AND_FIELD_SUCCESS =
  "CREATE_ATTRIBUTE_OPTION_FROM_SOURCE_AND_FIELD_SUCCESS";
export const CREATE_ATTRIBUTE_OPTION_FROM_SOURCE_AND_FIELD_FAIL =
  "CREATE_ATTRIBUTE_OPTION_FROM_SOURCE_AND_FIELD_FAIL";

export const UPDATE_ATTRIBUTE_OPTION_START = "UPDATE_ATTRIBUTE_OPTION_START";
export const UPDATE_ATTRIBUTE_OPTION_FAIL = "UPDATE_ATTRIBUTE_OPTION_FAIL";
export const UPDATE_ATTRIBUTE_OPTION_SUCCESS =
  "UPDATE_ATTRIBUTE_OPTION_SUCCESS";

export const DELETE_ATTRIBUTE_OPTION_START = "DELETE_ATTRIBUTE_OPTION_START";
export const DELETE_ATTRIBUTE_OPTION_FAIL = "DELETE_ATTRIBUTE_OPTION_FAIL";
export const DELETE_ATTRIBUTE_OPTION_SUCCESS =
  "DELETE_ATTRIBUTE_OPTION_SUCCESS";

export const UPDATE_ATTRIBUTE_OPTION_VALUE = "UPDATE_ATTRIBUTE_OPTION_VALUE";

export const GET_DIMENSION_LIST_START = "GET_DIMENSION_LIST_START";
export const GET_DIMENSION_LIST_SUCCESS = "GET_DIMENSION_LIST_SUCCESS";
export const GET_DIMENSION_LIST_FAIL = "GET_DIMENSION_LIST_FAIL";

export const LOAD_DIMENSION_START = "LOAD_DIMENSION_START";
export const LOAD_DIMENSION_SUCCESS = "LOAD_DIMENSION_SUCCESS";
export const LOAD_DIMENSION_FAIL = "LOAD_DIMENSION_FAIL";

export const ADD_DIMENSION_START = "ADD_DIMENSION_START";
export const ADD_DIMENSION_SUCCESS = "ADD_DIMENSION_SUCCESS";
export const ADD_DIMENSION_FAIL = "ADD_DIMENSION_FAIL";

export const UPDATE_DIMENSION_START = "UPDATE_DIMENSION_START";
export const UPDATE_DIMENSION_SUCCESS = "UPDATE_DIMENSION_SUCCESS";
export const UPDATE_DIMENSION_FAIL = "UPDATE_DIMENSION_FAIL";

export const REMOVE_DIMENSION_START = "REMOVE_DIMENSION_START";
export const REMOVE_DIMENSION_SUCCESS = "REMOVE_DIMENSION_SUCCESS";
export const REMOVE_DIMENSION_FAIL = "REMOVE_DIMENSION_FAIL";

export const GET_DIMENSION_DATA_START = "GET_DIMENSION_DATA_START";
export const GET_DIMENSION_DATA_NEXT = "GET_DIMENSION_DATA_NEXT";
export const GET_DIMENSION_DATA_SUCCESS = "GET_DIMENSION_DATA_SUCCESS";
export const GET_DIMENSION_DATA_FAIL = "GET_DIMENSION_DATA_FAIL";

export const ADD_ATTRIBUTE_TO_DIMENSION_START =
  "ADD_ATTRIBUTE_TO_DIMENSION_START";
export const ADD_ATTRIBUTE_TO_DIMENSION_SUCCESS =
  "ADD_ATTRIBUTE_TO_DIMENSION_SUCCESS";
export const ADD_ATTRIBUTE_TO_DIMENSION_FAIL =
  "ADD_ATTRIBUTE_TO_DIMENSION_FAIL";

export const REMOVE_ATTRIBUTE_TO_DIMENSION_START =
  "REMOVE_ATTRIBUTE_TO_DIMENSION_START";
export const REMOVE_ATTRIBUTE_TO_DIMENSION_SUCCESS =
  "REMOVE_ATTRIBUTE_TO_DIMENSION_SUCCESS";
export const REMOVE_ATTRIBUTE_TO_DIMENSION_FAIL =
  "REMOVE_ATTRIBUTE_TO_DIMENSION_FAIL";

export const SET_PRIMARY_KEY_ATTRIBUTE_TO_DIMENSION_START =
  "SET_PRIMARY_KEY_ATTRIBUTE_TO_DIMENSION_START";
export const SET_PRIMARY_KEY_ATTRIBUTE_TO_DIMENSION_SUCCESS =
  "SET_PRIMARY_KEY_ATTRIBUTE_TO_DIMENSION_SUCCESS";
export const SET_PRIMARY_KEY_ATTRIBUTE_TO_DIMENSION_FAIL =
  "SET_PRIMARY_KEY_ATTRIBUTE_TO_DIMENSION_FAIL";

export const EDIT_ATTRIBUTE_TO_DIMENSION_START =
  "EDIT_ATTRIBUTE_TO_DIMENSION_START";
export const EDIT_ATTRIBUTE_TO_DIMENSION_SUCCESS =
  "EDIT_ATTRIBUTE_TO_DIMENSION_SUCCESS";
export const EDIT_ATTRIBUTE_TO_DIMENSION_FAIL =
  "EDIT_ATTRIBUTE_TO_DIMENSION_FAIL";

export const CREATE_NEW_ATTRIBUTE_IN_DIMENSION =
  "CREATE_NEW_ATTRIBUTE_IN_DIMENSION";

export const GET_ATRIBUTE_DATA_SOURCES_START =
  "GET_ATRIBUTE_DATA_SOURCES_START";
export const GET_ATRIBUTE_DATA_SOURCES_SUCCESS =
  "GET_ATRIBUTE_DATA_SOURCES_SUCCESS";
export const GET_ATRIBUTE_DATA_SOURCES_FAIL = "GET_ATRIBUTE_DATA_SOURCES_FAIL";

export const GET_ATRIBUTE_DATA_SOURCE_FIELDS_START =
  "GET_ATRIBUTE_DATA_SOURCE_FIELDS_START";
export const GET_ATRIBUTE_DATA_SOURCE_FIELDS_SUCCESS =
  "GET_ATRIBUTE_DATA_SOURCE_FIELDS_SUCCESS";
export const GET_ATRIBUTE_DATA_SOURCE_FIELDS_FAIL =
  "GET_ATRIBUTE_DATA_SOURCE_FIELDS_FAIL";

export const UPDATE_ATRIBUTE_DATA_SOURCE_FIELD_START =
  "UPDATE_ATRIBUTE_DATA_SOURCE_FIELD_START";
export const UPDATE_ATRIBUTE_DATA_SOURCE_FIELD_SUCCESS =
  "UPDATE_ATRIBUTE_DATA_SOURCE_FIELD_SUCCESS";
export const UPDATE_ATRIBUTE_DATA_SOURCE_FIELD_FAIL =
  "UPDATE_ATRIBUTE_DATA_SOURCE_FIELD_FAIL";

export const UPDATE_DATA_SOURCE_START = "UPDATE_DATA_SOURCE_START";
export const UPDATE_DATA_SOURCE_SUCCESS = "UPDATE_DATA_SOURCE_SUCCESS";
export const UPDATE_DATA_SOURCE_FAIL = "UPDATE_DATA_SOURCE_FAIL";

export const SAVE_DIMENSION_DATA_START = "SAVE_DIMENSION_DATA_START";
export const SAVE_DIMENSION_DATA_SUCCESS = "SAVE_DIMENSION_DATA_SUCCESS";
export const SAVE_DIMENSION_DATA_FAIL = "SAVE_DIMENSION_DATA_FAIL";

export const ADD_DIMENSION_DATA_START = "ADD_DIMENSION_DATA_START";
export const ADD_DIMENSION_DATA_SUCCESS = "ADD_DIMENSION_DATA_SUCCESS";
export const ADD_DIMENSION_DATA_FAIL = "ADD_DIMENSION_DATA_FAIL";

export const UPDATE_DIMENSION_DATA_START = "UPDATE_DIMENSION_DATA_START";
export const UPDATE_DIMENSION_DATA_SUCCESS = "UPDATE_DIMENSION_DATA_SUCCESS";
export const UPDATE_DIMENSION_DATA_FAIL = "UPDATE_DIMENSION_DATA_FAIL";

export const DELETE_DIMENSION_DATA_START = "DELETE_DIMENSION_DATA_START";
export const DELETE_DIMENSION_DATA_SUCCESS = "DELETE_DIMENSION_DATA_SUCCESS";
export const DELETE_DIMENSION_DATA_FAIL = "DELETE_DIMENSION_DATA_FAIL";

export const GET_DATA_SOURCES_SUCCESS = "GET_DATA_SOURCES_SUCCESS";
export const GET_DATA_SOURCES_FAIL = "GET_DATA_SOURCES_FAIL";

export const GET_DATA_SOURCE_FIELDS_START = "GET_DATA_SOURCE_FIELDS_START";
export const GET_DATA_SOURCE_FIELDS_SUCCESS = "GET_DATA_SOURCE_FIELDS_SUCCESS";
export const GET_DATA_SOURCE_FIELDS_FAIL = "GET_DATA_SOURCE_FIELDS_FAIL";

export const PREVIEW_DATA_SOURCE_START = "PREVIEW_DATA_SOURCE_START";
export const PREVIEW_DATA_SOURCE_SUCCESS = "PREVIEW_DATA_SOURCE_SUCCESS";
export const PREVIEW_DATA_SOURCE_FAIL = "PREVIEW_DATA_SOURCE_FAIL";

export const RESCAN_DATA_SOURCE_START = "RESCAN_DATA_SOURCE_START";
export const RESCAN_DATA_SOURCE_SUCCESS = "RESCAN_DATA_SOURCE_SUCCESS";
export const RESCAN_DATA_SOURCE_FAIL = "RESCAN_DATA_SOURCE_FAIL";

export const ADD_MISSING_FIELDS_START = "ADD_MISSING_FIELDS_START";
export const ADD_MISSING_FIELDS_SUCCESS = "ADD_MISSING_FIELDS_SUCCESS";
export const ADD_MISSING_FIELDS_FAIL = "ADD_MISSING_FIELDS_FAIL";

export const DELETE_DATA_SOURCE_START = "DELETE_DATA_SOURCE_START";
export const DELETE_DATA_SOURCE_SUCCESS = "DELETE_DATA_SOURCE_SUCCESS";
export const DELETE_DATA_SOURCE_FAIL = "DELETE_DATA_SOURCE_FAIL";

export const CREATE_DATA_SOURCE_START = "CREATE_DATA_SOURCE_START";
export const CREATE_DATA_SOURCE_SUCCESS = "CREATE_DATA_SOURCE_SUCCESS";
export const CREATE_DATA_SOURCE_FAIL = "CREATE_DATA_SOURCE_FAIL";

export const ADD_DATA_SOURCE_FIELDS_START = "ADD_DATA_SOURCE_FIELDS_START";
export const ADD_DATA_SOURCE_FIELDS_SUCCESS = "ADD_DATA_SOURCE_FIELDS_SUCCESS";
export const ADD_DATA_SOURCE_FIELDS_FAIL = "ADD_DATA_SOURCE_FIELDS_FAIL";

export const CREATE_PAGE_START = "CREATE_PAGE_START";
export const CREATE_PAGE_SUCCESS = "CREATE_PAGE_SUCCESS";
export const CREATE_PAGE_FAIL = "CREATE_PAGE_FAIL";

export const DELETE_PAGE_START = "DELETE_PAGE_START";
export const DELETE_PAGE_SUCCESS = "DELETE_PAGE_SUCCESS";
export const DELETE_PAGE_SUCCESS_TOAST = "DELETE_PAGE_SUCCESS_TOAST";
export const DELETE_PAGE_FAIL = "DELETE_PAGE_FAIL";

export const UPDATE_PAGE_START = "UPDATE_PAGE_START";
export const UPDATE_PAGE_SUCCESS = "UPDATE_PAGE_SUCCESS";
export const UPDATE_PAGE_FAIL = "UPDATE_PAGE_FAIL";

export const GET_PAGES_START = "GET_PAGES_START";
export const GET_PAGES_SUCCESS = "GET_PAGES_SUCCESS";
export const GET_PAGES_FAIL = "GET_PAGES_FAIL";

export const REORDER_PAGES_START = "REORDER_PAGES_START";
export const REORDER_PAGES_SUCCESS = "REORDER_PAGES_SUCCESS";
export const REORDER_PAGES_FAIL = "REORDER_PAGES_FAIL";

export const DOWNLOAD_FILE_START = "DOWNLOAD_FILE_START";
export const DOWNLOAD_FILE_SUCCESS = "DOWNLOAD_FILE_SUCCESS";
export const DOWNLOAD_FILE_FAIL = "DOWNLOAD_FILE_FAIL";

export const GET_DATA_FRESHNESS_START = "GET_DATA_FRESHNESS_START";
export const GET_DATA_FRESHNESS_SUCCESS = "GET_DATA_FRESHNESS_SUCCESS";
export const GET_DATA_FRESHNESS_FAIL = "GET_DATA_FRESHNESS_FAIL";

export const GET_INSIGHTS_START = "GET_INSIGHTS_START";
export const GET_INSIGHTS_SUCCESS = "GET_INSIGHTS_SUCCESS";
export const GET_INSIGHTS_FAIL = "GET_INSIGHTS_FAIL";

export const CREATE_PAGE_BLOCK_START = "CREATE_PAGE_BLOCK_START";
export const CREATE_PAGE_BLOCK_SUCCESS = "CREATE_PAGE_BLOCK_SUCCESS";
export const CREATE_PAGE_BLOCK_FAIL = "CREATE_PAGE_BLOCK_FAIL";

export const UPDATE_PAGE_BLOCK_START = "UPDATE_PAGE_BLOCK_START";
export const UPDATE_PAGE_BLOCK_SUCCESS = "UPDATE_PAGE_BLOCK_SUCCESS";
export const UPDATE_PAGE_BLOCK_FAIL = "UPDATE_PAGE_BLOCK_FAIL";

export const DELETE_PAGE_BLOCK_START = "DELETE_PAGE_BLOCK_START";
export const DELETE_PAGE_BLOCK_SUCCESS = "DELETE_PAGE_BLOCK_SUCCESS";
export const DELETE_PAGE_BLOCK_FAIL = "DELETE_PAGE_BLOCK_FAIL";

export const SORT_BLOCKS_START = "SORT_BLOCKS_START";
export const SORT_BLOCKS_SUCCESS = "SORT_BLOCKS_SUCCESS";
export const SORT_BLOCKS_FAIL = "SORT_BLOCKS_FAIL";

export const SORT_HEADER_BLOCKS_START = "SORT_HEADER_BLOCKS_START";
export const SORT_HEADER_BLOCKS_SUCCESS = "SORT_HEADER_BLOCKS_SUCCESS";
export const SORT_HEADER_BLOCKS_FAIL = "SORT_HEADER_BLOCKS_FAIL";

export const CREATE_VISUALIZATION_START = "CREATE_VISUALIZATION_START";
export const CREATE_VISUALIZATION_SUCCESS = "CREATE_VISUALIZATION_SUCCESS";
export const CREATE_VISUALIZATION_FAIL = "CREATE_VISUALIZATION_FAIL";

export const UPDATE_VISUALIZATION_START = "UPDATE_VISUALIZATION_START";
export const UPDATE_VISUALIZATION_SUCCESS = "UPDATE_VISUALIZATION_SUCCESS";
export const UPDATE_VISUALIZATION_FAIL = "UPDATE_VISUALIZATION_FAIL";

export const DELETE_VISUALIZATION_START = "DELETE_VISUALIZATION_START";
export const DELETE_VISUALIZATION_SUCCESS = "DELETE_VISUALIZATION_SUCCESS";
export const DELETE_VISUALIZATION_FAIL = "DELETE_VISUALIZATION_FAIL";

export const RUN_QUERY_START = "RUN_QUERY_START";
export const RUN_QUERY_SUCCESS = "RUN_QUERY_SUCCESS";
export const RUN_QUERY_FAIL = "RUN_QUERY_FAIL";
export const RUN_QUERY_RESET = "RUN_QUERY_RESET";

export const RUN_QUERY_EXPORT_START = "RUN_QUERY_START";
export const RUN_QUERY_EXPORT_SUCCESS = "RUN_QUERY_SUCCESS";
export const RUN_QUERY_EXPORT_FAIL = "RUN_QUERY_FAIL";

export const SHOW_ACTION_TOAST_MESSAGE = "SHOW_ACTION_TOAST_MESSAGE";
export const HIDE_ACTION_TOAST_MESSAGE = "HIDE_ACTION_TOAST_MESSAGE";

export const SCHEDULE_EXPORT_START = "SCHEDULE_EXPORT_START";
export const SCHEDULE_EXPORT_SUCCESS = "SCHEDULE_EXPORT_SUCCESS";
export const SCHEDULE_EXPORT_FAIL = "SCHEDULE_EXPORT_FAIL";

export const ADD_APPROVER_USER_START = "ADD_APPROVER_USER_START";
export const ADD_APPROVER_USER_SUCCESS = "ADD_APPROVER_USER_SUCCESS";
export const ADD_APPROVER_USER_FAIL = "ADD_APPROVER_USER_FAIL";

export const REMOVE_APPROVER_USER_START = "REMOVE_APPROVER_USER_START";
export const REMOVE_APPROVER_USER_SUCCESS = "REMOVE_APPROVER_USER_SUCCESS";
export const REMOVE_APPROVER_USER_FAIL = "REMOVE_APPROVER_USER_FAIL";

export const APPROVE_PROCESSING_STATUS_START =
  "APPROVE_PROCESSING_STATUS_START";
export const APPROVE_PROCESSING_STATUS_SUCCESS =
  "APPROVE_PROCESSING_STATUS_SUCCESS";
export const APPROVE_PROCESSING_STATUS_FAIL = "APPROVE_PROCESSING_STATUS_FAIL";

export const REJECT_PROCESSING_STATUS_START = "REJECT_PROCESSING_STATUS_START";
export const REJECT_PROCESSING_STATUS_SUCCESS =
  "REJECT_PROCESSING_STATUS_SUCCESS";
export const REJECT_PROCESSING_STATUS_FAIL = "REJECT_PROCESSING_STATUS_FAIL";

export const PREVIEW_PROCESSING_FILE_START = "PREVIEW_PROCESSING_FILE_START";
export const PREVIEW_PROCESSING_FILE_SUCCESS =
  "PREVIEW_PROCESSING_FILE_SUCCESS";
export const PREVIEW_PROCESSING_FILE_FAIL = "PREVIEW_PROCESSING_FILE_FAIL";

export const DOWNLOAD_PROCESSING_FILE_START = "DOWNLOAD_PROCESSING_FILE_START";
export const DOWNLOAD_PROCESSING_FILE_SUCCESS =
  "DOWNLOAD_PROCESSING_FILE_SUCCESS";
export const DOWNLOAD_PROCESSING_FILE_FAIL = "DOWNLOAD_PROCESSING_FILE_FAIL";

export const GET_CONNECTIONS_START = "GET_CONNECTIONS_START";
export const GET_CONNECTIONS_SUCCESS = "GET_CONNECTIONS_SUCCESS";
export const GET_CONNECTIONS_FAIL = "GET_CONNECTIONS_FAIL";

export const DELETE_CONNECTION_START = "DELETE_CONNECTION_START";
export const DELETE_CONNECTION_SUCCESS = "DELETE_CONNECTION_SUCCESS";
export const DELETE_CONNECTION_FAIL = "DELETE_CONNECTION_FAIL";

export const CREATE_CONNECTION_START = "CREATE_CONNECTION_START";
export const CREATE_CONNECTION_SUCCESS = "CREATE_CONNECTION_SUCCESS";
export const CREATE_CONNECTION_FAIL = "CREATE_CONNECTION_FAIL";

export const UPDATE_CONNECTION_START = "UPDATE_CONNECTION_START";
export const UPDATE_CONNECTION_SUCCESS = "UPDATE_CONNECTION_SUCCESS";
export const UPDATE_CONNECTION_FAIL = "UPDATE_CONNECTION_FAIL";

export const TEST_CONNECTION_START = "TEST_CONNECTION_START";
export const TEST_CONNECTION_SUCCESS = "TEST_CONNECTION_SUCCESS";
export const TEST_CONNECTION_FAIL = "TEST_CONNECTION_FAIL";

export const CREATE_CONNECTION_OVERRIDE_START =
  "CREATE_CONNECTION_OVERRIDE_START";
export const CREATE_CONNECTION_OVERRIDE_SUCCESS =
  "CREATE_CONNECTION_OVERRIDE_SUCCESS";
export const CREATE_CONNECTION_OVERRIDE_FAIL =
  "CREATE_CONNECTION_OVERRIDE_FAIL";

export const UPDATE_CONNECTION_OVERRIDE_START =
  "UPDATE_CONNECTION_OVERRIDE_START";
export const UPDATE_CONNECTION_OVERRIDE_SUCCESS =
  "UPDATE_CONNECTION_OVERRIDE_SUCCESS";
export const UPDATE_CONNECTION_OVERRIDE_FAIL =
  "UPDATE_CONNECTION_OVERRIDE_FAIL";

export const DELETE_CONNECTION_OVERRIDE_START =
  "DELETE_CONNECTION_OVERRIDE_START";
export const DELETE_CONNECTION_OVERRIDE_SUCCESS =
  "DELETE_CONNECTION_OVERRIDE_SUCCESS";
export const DELETE_CONNECTION_OVERRIDE_FAIL =
  "DELETE_CONNECTION_OVERRIDE_FAIL";

export const GET_ETL_CONFIG_LIST_START = "GET_ETL_CONFIG_LIST_START";
export const GET_ETL_CONFIG_LIST_SUCCESS = "GET_ETL_CONFIG_LIST_SUCCESS";
export const GET_ETL_CONFIG_LIST_FAIL = "GET_ETL_CONFIG_LIST_FAIL";

export const GET_ETL_CONFIG_START = "GET_ETL_CONFIG_START";
export const GET_ETL_CONFIG_SUCCESS = "GET_ETL_CONFIG_SUCCESS";
export const GET_ETL_CONFIG_FAIL = "GET_ETL_CONFIG_FAIL";

export const CREATE_ETL_CONFIG_INIT = "CREATE_ETL_CONFIG_INIT";

export const CREATE_ETL_CONFIG_START = "CREATE_ETL_CONFIG_START";
export const CREATE_ETL_CONFIG_SUCCESS = "CREATE_ETL_CONFIG_SUCCESS";
export const CREATE_ETL_CONFIG_FAIL = "CREATE-ETL_CONFIG_FAIL";

export const UPDATE_ETL_CONFIG_START = "UPDATE_ETL_CONFIG_START";
export const UPDATE_ETL_CONFIG_SUCCESS = "UPDATE_ETL_CONFIG_SUCCESS";
export const UPDATE_ETL_CONFIG_FAIL = "UPDATE_ETL_CONFIG_FAIL";

export const DELETE_ETL_CONFIG_START = "DELETE_ETL_CONFIG_START";
export const DELETE_ETL_CONFIG_SUCCESS = "DELETE_ETL_CONFIG_SUCCESS";
export const DELETE_ETL_CONFIG_FAIL = "DELETE_ETL_CONFIG_FAIL";

export const GET_CONNECTION_TABLES_START = "GET_CONNECTION_TABLES_START";
export const GET_CONNECTION_TABLES_SUCCESS = "GET_CONNECTION_TABLES_SUCCESS";
export const GET_CONNECTION_TABLES_FAIL = "GET_CONNECTION_TABLES_FAIL";
export const GET_CONNECTION_TABLES_RESET = "GET_CONNECTION_TABLES_RESET";

export const UPDATE_PAGE_BLOCKS_START = "UPDATE_PAGE_BLOCKS_START";
export const UPDATE_PAGE_BLOCKS_SUCCESS = "UPDATE_PAGE_BLOCKS_SUCCESS";
export const UPDATE_PAGE_BLOCKS_FAIL = "UPDATE_PAGE_BLOCKS_FAIL";

export const GET_DATA_SOURCE_FIELDS_FOR_BLENDED_QUERY_START =
  "GET_DATA_SOURCE_FIELDS_FOR_BLENDED_QUERY_START";
export const GET_DATA_SOURCE_FIELDS_FOR_BLENDED_QUERY_SUCCESS =
  "GET_DATA_SOURCE_FIELDS_FOR_BLENDED_QUERY_SUCCESS";
export const GET_DATA_SOURCE_FIELDS_FOR_BLENDED_QUERY_FAIL =
  "GET_DATA_SOURCE_FIELDS_FOR_BLENDED_QUERY_FAIL";

export const GET_TABLE_PREVIEW_DATA_START = "GET_TABLE_PREVIEW_DATA_START";
export const GET_TABLE_PREVIEW_DATA_SUCCESS = "GET_TABLE_PREVIEW_DATA_SUCCESS";
export const GET_TABLE_PREVIEW_DATA_FAIL = "GET_TABLE_PREVIEW_DATA_FAIL";

export const GET_QUERY_EXEC_SETTINGS_RESET = "GET_QUERY_EXEC_SETTINGS_RESET";
export const GET_QUERY_EXEC_SETTINGS_START = "GET_QUERY_EXEC_SETTINGS_START";
export const GET_QUERY_EXEC_SETTINGS_SUCCESS =
  "GET_QUERY_EXEC_SETTINGS_SUCCESS";
export const GET_QUERY_EXEC_SETTINGS_FAIL = "GET_QUERY_EXEC_SETTINGS_FAIL";

export const GET_QUERY_EXEC_SETTINGS_SINGLE_RESET =
  "GET_QUERY_EXEC_SETTINGS_SINGLE_RESET";
export const GET_QUERY_EXEC_SETTINGS_SINGLE_START =
  "GET_QUERY_EXEC_SETTINGS_SINGLE_START";
export const GET_QUERY_EXEC_SETTINGS_SINGLE_SUCCESS =
  "GET_QUERY_EXEC_SETTINGS_SINGLE_SUCCESS";
export const GET_QUERY_EXEC_SETTINGS_SINGLE_FAIL =
  "GET_QUERY_EXEC_SETTINGS_SINGLE_FAIL";

export const SET_EXPLORER_MODE = "SET_EXPLORER_MODE";
export const CLEAR_EXPLORER_MODE = "CLEAR_EXPLORER_MODE";

export const GET_UNIQUE_START = "GET_UNIQUE_START";
export const GET_UNIQUE_SUCCESS = "GET_UNIQUE_SUCCESS";
export const GET_UNIQUE_FAIL = "GET_UNIQUE_FAIL";

export const GET_DATASOURCE_DATA_INIT = "GET_DATASOURCE_DATA_INIT";
export const GET_DATASOURCE_DATA_START = "GET_DATASOURCE_DATA_START";
export const GET_DATASOURCE_DATA_SUCCESS = "GET_DATASOURCE_DATA_SUCCESS";

export const GET_DATASOURCE_DATA_FAIL = "GET_DATASOURCE_DATA_FAIL";
export const UPLOAD_DATASOURCE_DATA_START = "UPLOAD_DATASOURCE_DATA_START";
export const UPLOAD_DATASOURCE_DATA_SUCCESS = "UPLOAD_DATASOURCE_DATA_SUCCESS";

export const UPLOAD_DATASOURCE_DATA_FAIL = "UPLOAD_DATASOURCE_DATA_FAIL";

export const SAVE_QUERY_EXEC_START = "SAVE_QUERY_EXEC_START";
export const SAVE_QUERY_EXEC_SUCCESS = "SAVE_QUERY_EXEC_SUCCESS";
export const SAVE_QUERY_EXEC_FAIL = "SAVE_QUERY_EXEC_FAIL";

export const DELETE_QUERY_EXEC_START = "DELETE_QUERY_EXEC_START";
export const DELETE_QUERY_EXEC_SUCCESS = "DELETE_QUERY_EXEC_SUCCESS";
export const DELETE_QUERY_EXEC_FAIL = "DELETE_QUERY_EXEC_FAIL";

export const UPDATE_QUERY_EXEC_START = "UPDATE_QUERY_EXEC_START";
export const UPDATE_QUERY_EXEC_SUCCESS = "UPDATE_QUERY_EXEC_SUCCESS";
export const UPDATE_QUERY_EXEC_FAIL = "UPDATE_QUERY_EXEC_FAIL";

export const SET_ACTIVE_EXEC_SETTINGS = "SET_ACTIVE_EXEC_SETTINGS";

export const STOP_DASHBOARD_SYNC = "STOP_DASHBOARD_SYNC";

export const UPDATE_DATA_SOURCE_FIELD_START = "UPDATE_DATA_SOURCE_FIELD_START";
export const UPDATE_DATA_SOURCE_FIELD_SUCCESS =
  "UPDATE_DATA_SOURCE_FIELD_SUCCESS";
export const UPDATE_DATA_SOURCE_FIELD_FAIL = "UPDATE_DATA_SOURCE_FIELD_FAIL";

export const DELETE_DATA_SOURCE_FIELD_START = "DELETE_DATA_SOURCE_FIELD_START";
export const DELETE_DATA_SOURCE_FIELD_SUCCESS =
  "DELETE_DATA_SOURCE_FIELD_SUCCESS";
export const DELETE_DATA_SOURCE_FIELD_FAIL = "DELETE_DATA_SOURCE_FIELD_FAIL";

export const CLEAR_CACHE_START = "CLEAR_CACHE_START";
export const CLEAR_CACHE_SUCCESS = "CLEAR_CACHE_SUCCESS";
export const CLEAR_CACHE_FAIL = "CLEAR_CACHE_FAIL";

export const ROW_EXPAND_EXEC_QUERY_START = "ROW_EXPAND_EXEC_QUERY_START";
export const ROW_EXPAND_EXEC_QUERY_SUCCESS = "ROW_EXPAND_EXEC_QUERY_SUCCESS";
export const ROW_EXPAND_EXEC_QUERY_FAIL = "ROW_EXPAND_EXEC_QUERY_FAIL";

export const ROW_EXPAND_EXEC_QUERY_EXPORT_ALL_START =
  "ROW_EXPAND_EXEC_QUERY_EXPORT_ALL_START";
export const ROW_EXPAND_EXEC_QUERY_EXPORT_ALL_SUCCESS =
  "ROW_EXPAND_EXEC_QUERY_EXPORT_ALL_SUCCESS";
export const ROW_EXPAND_EXEC_QUERY_EXPORT_ALL_FAIL =
  "ROW_EXPAND_EXEC_QUERY_EXPORT_ALL_FAIL";

export const CLEAR_EXPORT_ALL_OBJECT = "CLEAR_EXPORT_ALL_OBJECT";

export const GET_VISUALIZATION_PREVIEW_START =
  "GET_VISUALIZATION_PREVIEW_START";
export const GET_VISUALIZATION_PREVIEW_SUCCESS =
  "GET_VISUALIZATION_PREVIEW_SUCCESS";
export const GET_VISUALIZATION_PREVIEW_FAIL = "GET_VISUALIZATION_PREVIEW_FAIL";

export const SET_INVALID_PREVIEW_CONFIG_MESSAGE =
  "SET_INVALID_PREVIEW_CONFIG_MESSAGE";

export const RESET_VISUALIZATION_PREVIEW = "RESET_VISUALIZATION_PREVIEW";

export const ETL_CONFIG_LIST_DATABASES_CONFIG_START =
  "ETL_CONFIG_LIST_DATABASES_CONFIG_START";
export const ETL_CONFIG_LIST_DATABASES_CONFIG_SUCCESS =
  "ETL_CONFIG_LIST_DATABASES_CONFIG_SUCCESS";
export const ETL_CONFIG_LIST_DATABASES_CONFIG_FAIL =
  "ETL_CONFIG_LIST_DATABASES_CONFIG_FAIL";

export const ETL_CONFIG_LIST_SOURCE_DATABASES_CONFIG_START =
  "ETL_CONFIG_LIST_SOURCE_DATABASES_CONFIG_START";
export const ETL_CONFIG_LIST_SOURCE_DATABASES_CONFIG_SUCCESS =
  "ETL_CONFIG_LIST_SOURCE_DATABASES_CONFIG_SUCCESS";
export const ETL_CONFIG_LIST_SOURCE_DATABASES_CONFIG_FAIL =
  "ETL_CONFIG_LIST_SOURCE_DATABASES_CONFIG_FAIL";

export const ETL_CONFIG_LIST_TABLES_START = "ETL_CONFIG_LIST_TABLES_START";
export const ETL_CONFIG_LIST_TABLES_SUCCESS = "ETL_CONFIG_LIST_TABLES_SUCCESS";
export const ETL_CONFIG_LIST_TABLES_FAIL = "ETL_CONFIG_LIST_TABLES_FAIL";

export const ETL_CONFIG_LIST_FIELDS_START = "ETL_CONFIG_LIST_FIELDS_START";
export const ETL_CONFIG_LIST_FIELDS_SUCCESS = "ETL_CONFIG_LIST_FIELDS_SUCCESS";
export const ETL_CONFIG_LIST_FIELDS_FAIL = "ETL_CONFIG_LIST_FIELDS_FAIL";

export const SELECT_MULTI_RANGE_FILTER = "SELECT_MULTI_RANGE_FILTER";

export const SELECT_SLIDER = "SELECT_SLIDER";

export const SELECT_DATE_RANGE_FILTER = "SELECT_DATE_RANGE_FILTER";

export const ETL_CONFIG_RUN_JOB_START = "ETL_CONFIG_RUN_JOB_START";
export const ETL_CONFIG_RUN_JOB_SUCCESS = "ETL_CONFIG_RUN_JOB_SUCCESS";
export const ETL_CONFIG_RUN_JOB_FAIL = "ETL_CONFIG_RUN_JOB_FAIL";

export const ETL_CONFIG_PAUSE_JOB_START = "ETL_CONFIG_PAUSE_JOB_START";
export const ETL_CONFIG_PAUSE_JOB_SUCCESS = "ETL_CONFIG_PAUSE_JOB_SUCCESS";
export const ETL_CONFIG_PAUSE_JOB_FAIL = "ETL_CONFIG_PAUSE_JOB_FAIL";

export const ETL_CONFIG_RESUME_JOB_START = "ETL_CONFIG_RESUME_JOB_START";
export const ETL_CONFIG_RESUME_JOB_SUCCESS = "ETL_CONFIG_RESUME_JOB_SUCCESS";
export const ETL_CONFIG_RESUME_JOB_FAIL = "ETL_CONFIG_RESUME_JOB_FAIL";

export const ETL_CONFIG_GET_LOGS_START = "ETL_CONFIG_GET_LOGS_START";
export const ETL_CONFIG_GET_LOGS_SUCCESS = "ETL_CONFIG_GET_LOGS_SUCCESS";
export const ETL_CONFIG_GET_LOGS_FAIL = "ETL_CONFIG_GET_LOGS_FAIL";
export const ETL_CONFIG_CLEAR_LOGS = "ETL_CONFIG_CLEAR_LOGS";

export const SAVE_DATA_LOAD_ISSUE_NOTIFICATION_START =
  "SAVE_DATA_LOAD_ISSUE_NOTIFICATION_START";
export const SAVE_DATA_LOAD_ISSUE_NOTIFICATION_SUCCESS =
  "SAVE_DATA_LOAD_ISSUE_NOTIFICATION_SUCCESS";
export const SAVE_DATA_LOAD_ISSUE_NOTIFICATION_FAIL =
  "SAVE_DATA_LOAD_ISSUE_NOTIFICATION_FAIL";

export const GET_ISSUE_NOTIFICATIONS_START = "GET_ISSUE_NOTIFICATIONS_START";
export const GET_ISSUE_NOTIFICATIONS_SUCCESS =
  "GET_ISSUE_NOTIFICATIONS_SUCCESS";
export const GET_ISSUE_NOTIFICATIONS_FAIL = "GET_ISSUE_NOTIFICATIONS_FAIL";

export const CALCULATE_INSIGHTS_START = "CALCULATE_INSIGHTS_START";
export const CALCULATE_INSIGHTS_SUCCESS = "CALCULATE_INSIGHTS_SUCCESS";
export const CALCULATE_INSIGHTS_FAIL = "CALCULATE_INSIGHTS_FAIL";

export const MONITORING_QUERY_LIST_INIT = "MONITORING_QUERY_LIST_INIT";
export const MONITORING_QUERY_LIST_START = "MONITORING_QUERY_LIST_START";
export const MONITORING_QUERY_LIST_SUCCESS = "MONITORING_QUERY_LIST_SUCCESS";
export const MONITORING_QUERY_LIST_FAIL = "MONITORING_QUERY_LIST_FAIL";

export const MONITORING_QUERY_CREATE_START = "MONITORING_QUERY_CREATE_START";
export const MONITORING_QUERY_CREATE_SUCCESS =
  "MONITORING_QUERY_CREATE_SUCCESS";
export const MONITORING_QUERY_CREATE_FAIL = "MONITORING_QUERY_CREATE_FAIL";

export const MONITORING_QUERY_GET_SALESFORCE_REPORTS_START =
  "MONITORING_QUERY_GET_SALESFORCE_REPORTS_START";
export const MONITORING_QUERY_GET_SALESFORCE_REPORTS_SUCCESS =
  "MONITORING_QUERY_GET_SALESFORCE_REPORTS_SUCCESS";
export const MONITORING_QUERY_GET_SALESFORCE_REPORTS_FAIL =
  "MONITORING_QUERY_GET_SALESFORCE_REPORTS_FAIL";

export const MONITORING_QUERY_GET_SALESFORCE_REPORT_COLUMNS_START =
  "MONITORING_QUERY_GET_SALESFORCE_REPORT_COLUMNS_START";
export const MONITORING_QUERY_GET_SALESFORCE_REPORT_COLUMNS_SUCCESS =
  "MONITORING_QUERY_GET_SALESFORCE_REPORT_COLUMNS_SUCCESS";
export const MONITORING_QUERY_GET_SALESFORCE_REPORT_COLUMNS_FAIL =
  "MONITORING_QUERY_GET_SALESFORCE_REPORT_COLUMNS_FAIL";

export const MONITORING_QUERY_DELETE_SALESFORCE_REPORT_START =
  "MONITORING_QUERY_DELETE_SALESFORCE_REPORT_START";
export const MONITORING_QUERY_DELETE_SALESFORCE_REPORT_SUCCESS =
  "MONITORING_QUERY_DELETE_SALESFORCE_REPORT_SUCCESS";
export const MONITORING_QUERY_DELETE_SALESFORCE_REPORT_FAIL =
  "MONITORING_QUERY_DELETE_SALESFORCE_REPORT_FAIL";

export const SALESFORCE_GET_OBJECTS_START = "SALESFORCE_GET_OBJECTS_START";
export const SALESFORCE_GET_OBJECTS_SUCCESS = "SALESFORCE_GET_OBJECTS_SUCCESS";
export const SALESFORCE_GET_OBJECTS_FAIL = "SALESFORCE_GET_OBJECTS_FAIL";

export const SALESFORCE_MARKETING_CLOUD_GET_OBJECTS_START =
  "SALESFORCE_GET_OBJECTS_START";
export const SALESFORCE_MARKETING_CLOUD_GET_OBJECTS_SUCCESS =
  "SALESFORCE_GET_OBJECTS_SUCCESS";
export const SALESFORCE_MARKETING_CLOUD_GET_OBJECTS_FAIL =
  "SALESFORCE_GET_OBJECTS_FAIL";

export const SAGE_GET_OBJECTS_START = "SAGE_GET_OBJECTS_START";
export const SAGE_GET_OBJECTS_SUCCESS = "SAGE_GET_OBJECTS_SUCCESS";
export const SAGE_GET_OBJECTS_FAIL = "SAGE_GET_OBJECTS_FAIL";

export const SHIPSTATION_GET_ORDERS_START = "SHIPSTATION_GET_ORDERS_START";
export const SHIPSTATION_GET_ORDERS_SUCCESS = "SHIPSTATION_GET_ORDERS_SUCCESS";
export const SHIPSTATION_GET_ORDERS_FAIL = "SHIPSTATION_GET_ORDERS_FAIL";

export const SET_CURRENT_DATE_FILTER_TERM = "SET_CURRENT_DATE_FILTER_TERM";

export const LOAD_PERMISSION_LIST_START = "LOAD_PERMISSION_LIST_START";
export const LOAD_PERMISSION_LIST_SUCCESS = "LOAD_PERMISSION_LIST_SUCCESS";
export const LOAD_PERMISSION_LIST_FAIL = "LOAD_PERMISSION_LIST_FAIL";

export const DELETE_USER_ROLE_START = "DELETE_USER_ROLE_START";
export const DELETE_USER_ROLE_SUCCESS = "DELETE_USER_ROLE_SUCCESS";
export const DELETE_USER_ROLE_FAIL = "DELETE_USER_ROLE_FAIL";

export const GET_ALL_DATA_SOURCES_START = "GET_ALL_DATA_SOURCES_START";
export const GET_ALL_DATA_SOURCES_SUCCESS = "GET_ALL_DATA_SOURCES_SUCCESS";
export const GET_ALL_DATA_SOURCES_FAIL = "GET_ALL_DATA_SOURCES_FAIL";

export const EXTEND_MENU_FILTERS = "EXTEND_MENU_FILTERS";

export const GET_MY_PERMISSIONS_START = "GET_MY_PERMISSIONS_START";
export const GET_MY_PERMISSIONS_SUCCESS = "GET_MY_PERMISSIONS_SUCCESS";
export const GET_MY_PERMISSIONS_FAIL = "GET_MY_PERMISSIONS_FAIL";

export const HANDLE_TABLE_PAGINATION = "HANDLE_TABLE_PAGINATION";

export const REMOVE_DEEPER_EXPANDED_ROWS = "REMOVE_DEEPER_EXPANDED_ROWS";

export const CHART_BUILDER_SET_CHART_TYPE = "CHART_BUILDER_SET_CHART_TYPE";
export const CHART_BUILDER_SET_X_VALUE = "CHART_BUILDER_SET_X_VALUE";
export const CHART_BUILDER_SET_Y_VALUE = "CHART_BUILDER_SET_Y_VALUE";
export const CHART_BUILDER_SET_GROUP_BY = "CHART_BUILDER_SET_GROUP_BY";

export const GET_DATA_DICTIONARY_START = "GET_DATA_DICTIONARY_START";
export const GET_DATA_DICTIONARY_SUCCESS = "GET_DATA_DICTIONARY_SUCCESS";
export const GET_DATA_DICTIONARY_FAIL = "GET_DATA_DICTIONARY_FAIL";
export const GET_DATA_DICTIONARY_RESET = "GET_DATA_DICTIONARY_RESET";

export const CHANGE_COMPARISON_TYPE = "CHANGE_COMPARISON_TYPE";
export const CHANGE_COMPARISON_MODE_FILTER_NAME =
  "CHANGE_COMPARISON_MODE_FILTER_NAME";

export const GET_HEADER_KPIS_START = "GET_HEADER_KPIS_START";
export const GET_HEADER_KPIS_SUCCESS = "GET_HEADER_KPIS_SUCCESS";
export const GET_HEADER_KPIS_FAIL = "GET_HEADER_KPIS_FAIL";
export const RESET_HEADER_KPIS = "RESET_HEADER_KPIS";

export const GET_SAMPLE_VALUES_FROM_STAGING_START =
  "GET_SAMPLE_VALUES_FROM_STAGING_START";
export const GET_SAMPLE_VALUES_FROM_STAGING_SUCCESS =
  "GET_SAMPLE_VALUES_FROM_STAGING_SUCCESS";
export const GET_SAMPLE_VALUES_FROM_STAGING_FAIL =
  "GET_SAMPLE_VALUES_FROM_STAGING_FAIL";
export const GET_SAMPLE_VALUES_FROM_STAGING = "GET_SAMPLE_VALUES_FROM_STAGING";

export const GET_UNIQUE_COLUMN_VALUES_FROM_STAGING_START =
  "GET_UNIQUE_COLUMN_VALUES_FROM_STAGING_START";
export const GET_UNIQUE_COLUMN_VALUES_FROM_STAGING_SUCCESS =
  "GET_UNIQUE_COLUMN_VALUES_FROM_STAGING_SUCCESS";
export const GET_UNIQUE_COLUMN_VALUES_FROM_STAGING_FAIL =
  "GET_UNIQUE_COLUMN_VALUES_FROM_STAGING_FAIL";

export const GET_UNIQUE_DATA_START = "GET_UNIQUE_DATA_START";
export const GET_UNIQUE_DATA_SUCCESS = "GET_UNIQUE_DATA_SUCCESS";
export const GET_UNIQUE_DATA_FAIL = "GET_UNIQUE_DATA_FAIL";

export const CLEAR_SOURCE_DATA = "CLEAR_SOURCE_DATA";

export const POST_DATA_STRUCTURE_START = "POST_DATA_STRUCTURE_START";
export const POST_DATA_STRUCTURE_SUCCESS = "POST_DATA_STRUCTURE_SUCCESS";
export const POST_DATA_STRUCTURE_FAIL = "POST_DATA_STRUCTURE_FAIL";

export const LOAD_DATA_STRUCTURE_START = "LOAD_DATA_STRUCTURE_START";
export const LOAD_DATA_STRUCTURE_SUCCESS = "LOAD_DATA_STRUCTURE_SUCCESS";
export const LOAD_DATA_STRUCTURE_FAIL = "LOAD_DATA_STRUCTURE_FAIL";

export const LOAD_SEED_STAGING_DATA = "LOAD_SEED_STAGING_DATA";

export const COMPLETE_WAREHOUSE_CREATE = "COMPLETE_WAREHOUSE_CREATE";
export const LOAD_STAGING_TABLES_COMPLETE = "LOAD_STAGING_TABLES_COMPLETE";

export const GET_WATCHLIST_TABLE_DATA_START = "GET_WATCHLIST_TABLE_DATA_START";
export const GET_WATCHLIST_TABLE_DATA_SUCCESS =
  "GET_WATCHLIST_TABLE_DATA_SUCCESS";
export const GET_WATCHLIST_TABLE_DATA_FAIL = "GET_WATCHLIST_TABLE_DATA_FAIL";

export const GET_WATCHLISTS_START = "GET_WATCHLISTS_START";
export const GET_WATCHLISTS_SUCCESS = "GET_WATCHLISTS_SUCCESS";
export const GET_WATCHLISTS_FAIL = "GET_WATCHLISTS_FAIL";

export const SET_WATCHLIST_CONFIG_START = "SET_WATCHLIST_CONFIG_START";
export const SET_WATCHLIST_CONFIG_SUCCESS = "SET_WATCHLIST_CONFIG_SUCCESS";
export const SET_WATCHLIST_CONFIG_FAIL = "SET_WATCHLIST_CONFIG_FAIL";

export const LOAD_COUNTIES_START = "LOAD_COUNTIES_START";
export const LOAD_COUNTIES_SUCCESS = "LOAD_COUNTIES_SUCCESS";
export const LOAD_COUNTIES_FAIL = "LOAD_COUNTIES_FAIL";

export const LOAD_STATES_START = "LOAD_STATES_START";
export const LOAD_STATES_SUCCESS = "LOAD_STATES_SUCCESS";
export const LOAD_STATES_FAIL = "LOAD_STATES_FAIL";

export const LOAD_ZIPCODES_START = "LOAD_ZIPCODES_START";
export const LOAD_ZIPCODES_SUCCESS = "LOAD_ZIPCODES_SUCCESS";
export const LOAD_ZIPCODES_FAIL = "LOAD_ZIPCODES_FAIL";

export const LOAD_GEO_MAP_DETAILS_START = "LOAD_GEO_MAP_DETAILS_START";
export const LOAD_GEO_MAP_DETAILS_SUCCESS = "LOAD_GEO_MAP_DETAILS_SUCCESS";
export const LOAD_GEO_MAP_DETAILS_FAIL = "LOAD_GEO_MAP_DETAILS_FAIL";

export const RUN_PARAMETERIZED_SAMPLE_START = "RUN_PARAMETERIZED_SAMPLE_START";
export const RUN_PARAMETERIZED_SAMPLE_SUCCESS =
  "RUN_PARAMETERIZED_SAMPLE_SUCCESS";
export const RUN_PARAMETERIZED_SAMPLE_FAIL = "RUN_PARAMETERIZED_SAMPLE_FAIL";

export const UPDATE_TENANT_CONFIG = "UPDATE_TENANT_CONFIG";

export const SAVE_DATE_FILTER_SETTINGS_START =
  "SAVE_DATE_FILTER_SETTINGS_START";
export const SAVE_DATE_FILTER_SETTINGS_SUCCESS =
  "SAVE_DATE_FILTER_SETTINGS_SUCCESS";
export const SAVE_DATE_FILTER_SETTINGS_FAIL = "SAVE_DATE_FILTER_SETTINGS_FAIL";

export const SET_FULL_PAGE_STICKY_HEADERS = "SET_FULL_PAGE_STICKY_HEADERS";

export const SET_FULL_PAGE_SCREENSHOT_MODE = "SET_FULL_PAGE_SCREENSHOT_MODE";

export const SELECT_SINGLE_DATE_FILTER = "SELECT_SINGLE_DATE_FILTER";

export const SELECT_DATE_PRESETS_FILTER = "SELECT_DATE_PRESETS_FILTER";

export const CLONE_PAGE_START = "CLONE_PAGE_START";
export const CLONE_PAGE_SUCCESS = "CLONE_PAGE_SUCCESS";
export const CLONE_PAGE_FAIL = "CLONE_PAGE_FAIL";

export const CHARTS_MONITORING_START = "CHARTS_MONITORING_START";
export const CHARTS_MONITORING_SUCCESS = "CHARTS_MONITORING_SUCCESS";
export const CHARTS_MONITORING_FAIL = "CHARTS_MONITORING_FAIL";

export const GET_CHARTS_MONITORING_HISTORY_START =
  "GET_CHARTS_MONITORING_HISTORY_START";
export const GET_CHARTS_MONITORING_HISTORY_SUCCESS =
  "GET_CHARTS_MONITORING_HISTORY_SUCCESS";
export const GET_CHARTS_MONITORING_HISTORY_FAIL =
  "GET_CHARTS_MONITORING_HISTORY_FAIL";

export const SELECT_ALL_COMPARISON_FILTERS = "SELECT_ALL_COMPARISON_FILTERS";
export const SELECT_ALL_GENERAL_FILTERS = "SELECT_ALL_GENERAL_FILTERS";

export const LOAD_BLUEPRINTS = "LOAD_BLUEPRINTS";
export const LOAD_BLUEPRINT_CHAINS = "LOAD_BLUEPRINT_CHAINS";
export const LOAD_TABLES_FROM_WAREHOUSE = "LOAD_TABLES_FROM_WAREHOUSE";

export const BLUEPRINTS_INIT_COMPLETE = "BLUEPRINTS_INIT_COMPLETE";

export const CREATE_BLUEPRINT_START = "CREATE_BLUEPRINT_START";
export const CREATE_BLUEPRINT_SUCCESS = "CREATE_BLUEPRINT_SUCCESS";
export const CREATE_BLUEPRINT_FAIL = "CREATE_BLUEPRINT_FAIL";

export const SAVE_BLUEPRINT_INPUT_START = "SAVE_BLUEPRINT_INPUT_START";
export const SAVE_BLUEPRINT_INPUT_SUCCESS = "SAVE_BLUEPRINT_INPUT_SUCCESS";
export const SAVE_BLUEPRINT_INPUT_FAIL = "SAVE_BLUEPRINT_INPUT_FAIL";

export const DELETE_INPUT_CHAIN_START = "DELETE_INPUT_CHAIN_START";
export const DELETE_INPUT_CHAIN_SUCCESS = "DELETE_INPUT_CHAIN_SUCCESS";
export const DELETE_INPUT_CHAIN_FAIL = "DELETE_INPUT_CHAIN_FAIL";

export const PROCESS_RULESET = "PROCESS_RULESET";

export const REFRESH_POWER_EDITOR_PREVIEW = "REFRESH_POWER_EDITOR_PREVIEW";

export const RECACHE_MENU_FILTERS_START = "RECACHE_MENU_FILTERS_START";
export const RECACHE_MENU_FILTERS_SUCCESS = "RECACHE_MENU_FILTERS_SUCCESS";
export const RECACHE_MENU_FILTERS_FAIL = "RECACHE_MENU_FILTERS_FAIL";

export const RECACHE_MENU_FILTER_START = "RECACHE_MENU_FILTER_START";
export const RECACHE_MENU_FILTER_SUCCESS = "RECACHE_MENU_FILTER_SUCCESS";
export const RECACHE_MENU_FILTER_FAIL = "RECACHE_MENU_FILTER_FAIL";

export const SAVE_SELECTED_DATE_PESETS_FILTER_MAP =
  "SAVE_SELECTED_DATE_PESETS_FILTER_MAP";

export const GET_MENU_FILTER_VALUES_START = "GET_MENU_FILTER_VALUES_START";
export const GET_MENU_FILTER_VALUES_SUCCESS = "GET_MENU_FILTER_VALUES_SUCCESS";
export const GET_MENU_FILTER_VALUES_FAIL = "GET_MENU_FILTER_VALUES_FAIL";

export const INITIALIZE_ACTIVE_TABLE_START = "INITIALIZE_ACTIVE_TABLE_START";
export const INITIALIZE_ACTIVE_TABLE_SUCCESS =
  "INITIALIZE_ACTIVE_TABLE_SUCCESS";
export const INITIALIZE_ACTIVE_TABLE_FAIL = "INITIALIZE_ACTIVE_TABLE_FAIL";

export const INITIALIZE_INFO_CARDS_START = "INITIALIZE_INFO_CARDS_START";
export const INITIALIZE_INFO_CARDS_SUCCESS = "INITIALIZE_INFO_CARDS_SUCCESS";
export const INITIALIZE_INFO_CARDS_FAIL = "INITIALIZE_INFO_CARDS_FAIL";

export const CREATE_NEW_ACTIVE_TABLE_START = "CREATE_NEW_ACTIVE_TABLE_START";
export const CREATE_NEW_ACTIVE_TABLE_SUCCESS =
  "CREATE_NEW_ACTIVE_TABLE_SUCCESS";
export const CREATE_NEW_ACTIVE_TABLE_FAIL = "CREATE_NEW_ACTIVE_TABLE_FAIL";
export const CREATE_NEW_ACTIVE_TABLE_UPDATE_ERROR_MESSAGE =
  "CREATE_NEW_ACTIVE_TABLE_UPDATE_ERROR_MESSAGE";

export const UPDATE_ACTIVE_TABLE_CHART_CONFIG_START =
  "UPDATE_ACTIVE_TABLE_CHART_CONFIG_START";
export const UPDATE_ACTIVE_TABLE_CHART_CONFIG_SUCCESS =
  "UPDATE_ACTIVE_TABLE_CHART_CONFIG_SUCCESS";
export const UPDATE_ACTIVE_TABLE_CHART_CONFIG_FAIL =
  "UPDATE_ACTIVE_TABLE_CHART_CONFIG_FAIL";

export const LOAD_ACTIVE_TABLES_START = "LOAD_ACTIVE_TABLES_START";
export const LOAD_ACTIVE_TABLES_SUCCESS = "LOAD_ACTIVE_TABLES_SUCCESS";
export const LOAD_ACTIVE_TABLES_FAIL = "LOAD_ACTIVE_TABLES_FAIL";

export const CREATE_NEW_ACTIVE_TABLE_PROCESS_COMPLETE =
  "CREATE_NEW_ACTIVE_TABLE_PROCESS_COMPLETE";

export const GET_ACTIVE_TABLE_START = "GET_ACTIVE_TABLE_START";
export const GET_ACTIVE_TABLE_SUCCESS = "GET_ACTIVE_TABLE_SUCCESS";
export const GET_ACTIVE_TABLE_FAIL = "GET_ACTIVE_TABLE_FAIL";

export const GET_ACTIVE_TABLE_DATA_START = "GET_ACTIVE_TABLE_DATA_START";
export const GET_ACTIVE_TABLE_DATA_SUCCESS = "GET_ACTIVE_TABLE_DATA_SUCCESS";
export const GET_ACTIVE_TABLE_DATA_FAIL = "GET_ACTIVE_TABLE_DATA_FAIL";

export const ADD_ACTIVE_TABLE_ROW_START = "ADD_ACTIVE_TABLE_ROW_START";
export const ADD_ACTIVE_TABLE_ROW_SUCCESS = "ADD_ACTIVE_TABLE_ROW_SUCCESS";
export const ADD_ACTIVE_TABLE_ROW_FAIL = "ADD_ACTIVE_TABLE_ROW_FAIL";

export const UPDATE_ACTIVE_TABLE_START = "UPDATE_ACTIVE_TABLE_START";
export const UPDATE_ACTIVE_TABLE_SUCCESS = "UPDATE_ACTIVE_TABLE_SUCCESS";
export const UPDATE_ACTIVE_TABLE_COLUMN_SUCCESS =
  "UPDATE_ACTIVE_TABLE_COLUMN_SUCCESS";
export const UPDATE_ACTIVE_TABLE_FAIL = "UPDATE_ACTIVE_TABLE_FAIL";

export const UPDATE_ACTIVE_TABLE_ROW_START = "UPDATE_ACTIVE_TABLE_ROW_START";
export const UPDATE_ACTIVE_TABLE_ROW_SUCCESS =
  "UPDATE_ACTIVE_TABLE_ROW_SUCCESS";
export const UPDATE_ACTIVE_TABLE_ROW_FAIL = "UPDATE_ACTIVE_TABLE_ROW_FAIL";
export const UPDATE_ACTIVE_TABLE_ROW_BY_WEBSOCKET =
  "UPDATE_ACTIVE_TABLE_ROW_BY_WEBSOCKET";
export const BULK_UPDATE_ACTIVE_TABLE_ROW_BY_WEBSOCKET =
  "BULK_UPDATE_ACTIVE_TABLE_ROW_BY_WEBSOCKET";

export const GET_REPORTED_QUERIES_START = "GET_REPORTED_QUERIES_START";
export const GET_REPORTED_QUERIES_SUCCESS = "GET_REPORTED_QUERIES_SUCCESS";
export const GET_REPORTED_QUERIES_FAIL = "GET_REPORTED_QUERIES_FAIL";

export const CREATE_REPORTED_QUERIES_START = "CREATE_REPORTED_QUERIES_START";
export const CREATE_REPORTED_QUERIES_SUCCESS =
  "CREATE_REPORTED_QUERIES_SUCCESS";
export const CREATE_REPORTED_QUERIES_FAIL = "CREATE_REPORTED_QUERIES_FAIL";

export const UPDATE_REPORTED_QUERIES_START = "UPDATE_REPORTED_QUERIES_START";
export const UPDATE_REPORTED_QUERIES_SUCCESS =
  "UPDATE_REPORTED_QUERIES_SUCCESS";
export const UPDATE_REPORTED_QUERIES_FAIL = "UPDATE_REPORTED_QUERIES_FAIL";

export const GET_REPORTED_QUERY_START = "GET_REPORTED_QUERY_START";
export const GET_REPORTED_QUERY_SUCCESS = "GET_REPORTED_QUERY_SUCCESS";
export const GET_REPORTED_QUERY_FAIL = "GET_REPORTED_QUERY_FAIL";
export const CLEAR_REPORTED_QUERY = "CLEAR_REPORTED_QUERY";

export const DELETE_REPORTED_QUERIES_START = "DELETE_REPORTED_QUERIES_START";
export const DELETE_REPORTED_QUERIES_SUCCESS =
  "DELETE_REPORTED_QUERIES_SUCCESS";
export const DELETE_REPORTED_QUERIES_FAIL = "DELETE_REPORTED_QUERIES_FAIL";

export const CREATE_REPORTED_QUERIES_RECIPIENT_START =
  "CREATE_REPORTED_QUERIES_RECIPIENTS_START";
export const CREATE_REPORTED_QUERIES_RECIPIENT_SUCCESS =
  "CREATE_REPORTED_QUERIES_RECIPIENTS_SUCCESS";
export const CREATE_REPORTED_QUERIES_RECIPIENT_FAIL =
  "CREATE_REPORTED_QUERIES_RECIPIENTS_FAIL";

export const DELETE_REPORTED_QUERIES_RECIPIENT_START =
  "DELETE_REPORTED_QUERIES_RECIPIENT_START";
export const DELETE_REPORTED_QUERIES_RECIPIENT_SUCCESS =
  "DELETE_REPORTED_QUERIES_RECIPIENT_SUCCESS";
export const DELETE_REPORTED_QUERIES_RECIPIENT_FAIL =
  "DELETE_REPORTED_QUERIES_RECIPIENT_FAIL";

export const CREATE_REPORTED_QUERIES_GROUP_RECIPIENT_START =
  "CREATE_REPORTED_QUERIES_GROUP_RECIPIENT_START";
export const CREATE_REPORTED_QUERIES_GROUP_RECIPIENT_SUCCESS =
  "CREATE_REPORTED_QUERIES_GROUP_RECIPIENT_SUCCESS";
export const CREATE_REPORTED_QUERIES_GROUP_RECIPIENT_FAIL =
  "CREATE_REPORTED_QUERIES_GROUP_RECIPIENT_FAIL";

export const DELETE_REPORTED_QUERIES_GROUP_RECIPIENT_START =
  "DELETE_REPORTED_QUERIES_GROUP_RECIPIENT_START";
export const DELETE_REPORTED_QUERIES_GROUP_RECIPIENT_SUCCESS =
  "DELETE_REPORTED_QUERIES_GROUP_RECIPIENT_SUCCESS";
export const DELETE_REPORTED_QUERIES_GROUP_RECIPIENT_FAIL =
  "DELETE_REPORTED_QUERIES_GROUP_RECIPIENT_FAIL";

export const PREVIEW_EMAIL_START = "PREVIEW_EMAIL_START";
export const PREVIEW_EMAIL_SUCCESS = "PREVIEW_EMAIL_SUCCESS";
export const PREVIEW_EMAIL_FAIL = "PREVIEW_EMAIL_FAIL";

export const SAVE_AT_VIEW_START = "SAVE_AT_VIEW_START";
export const SAVE_AT_VIEW_SUCCESS = "SAVE_AT_VIEW_SUCCESS";
export const SAVE_AT_VIEW_FAIL = "SAVE_AT_VIEW_FAIL";

export const UPDATE_AT_VIEW_START = "UPDATE_AT_VIEW_START";
export const UPDATE_AT_VIEW_SUCCESS = "UPDATE_AT_VIEW_SUCCESS";
export const UPDATE_AT_VIEW_FAIL = "UPDATE_AT_VIEW_FAIL";
export const SAVE_STICKY_DATE_TYPE = "SAVE_STICKY_DATE_TYPE";

export const DELETE_AT_VIEW_START = "DELETE_AT_VIEW_START";
export const DELETE_AT_VIEW_SUCCESS = "DELETE_AT_VIEW_SUCCESS";
export const DELETE_AT_VIEW_FAIL = "DELETE_AT_VIEW_FAIL";

export const GET_TABLE_DATA_START = "GET_TABLE_DATA_START";
export const GET_TABLE_DATA_SUCCESS = "GET_TABLE_DATA_SUCCESS";
export const GET_TABLE_DATA_FAIL = "GET_TABLE_DATA_FAIL";

export const CLEAR_ACTIVE_TABLE = "CLEAR_ACTIVE_TABLE";
export const SET_ACTIVE_TABLE_CURRENT_VIEW_UUID =
  "SET_ACTIVE_TABLE_CURRENT_VIEW_UUID";

export const SET_LOCAL_DRILLDOWNS_DATA = "SET_LOCAL_DRILLDOWNS_DATA";

export const RUN_ON_QUERY_FILTERS_CHECKED = "RUN_ON_QUERY_FILTERS_CHECKED";

export const SET_ROW_EXPANDED_CHARTS_HEIGHT = "SET_ROW_EXPANDED_CHARTS_HEIGHT";

export const GET_COMMENTS_TABLE_START = "GET_COMMENTS_TABLE_START";
export const GET_COMMENTS_TABLE_SUCCESS = "GET_COMMENTS_TABLE_SUCCESS";
export const GET_COMMENTS_TABLE_FAIL = "GET_COMMENTS_TABLE_FAIL";

export const CREATE_COMMENTS_START = "CREATE_COMMENTS_START";
export const CREATE_COMMENTS_SUCCESS = "CREATE_COMMENTS_SUCCESS";
export const CREATE_COMMENTS_FAIL = "CREATE_COMMENTS_FAIL";

export const UPDATE_COMMENTS_START = "UPDATE_COMMENTS_START";
export const UPDATE_COMMENTS_SUCCESS = "UPDATE_COMMENTS_SUCCESS";
export const UPDATE_COMMENTS_FAIL = "UPDATE_COMMENTS_FAIL";

export const DELETE_COMMENTS_START = "DELETE_COMMENTS_START";
export const DELETE_COMMENTS_SUCCESS = "DELETE_COMMENTS_SUCCESS";
export const DELETE_COMMENTS_FAIL = "DELETE_COMMENTS_FAIL";

export const SET_ACTIVE_EXEC_ERRORS = "SET_ACTIVE_EXEC_ERRORS";

export const GET_QUERY_HTML_TEMPLATES_START = "GET_QUERY_HTML_TEMPLATES_START";
export const GET_QUERY_HTML_TEMPLATES_SUCCESS =
  "GET_QUERY_HTML_TEMPLATES_SUCCESS";
export const GET_QUERY_HTML_TEMPLATES_FAIL = "GET_QUERY_HTML_TEMPLATES_FAIL";

export const CREATE_QUERY_HTML_TEMPLATES_START =
  "CREATE_QUERY_HTML_TEMPLATES_START";
export const CREATE_QUERY_HTML_TEMPLATES_SUCCESS =
  "CREATE_QUERY_HTML_TEMPLATES_SUCCESS";
export const CREATE_QUERY_HTML_TEMPLATES_FAIL =
  "CREATE_QUERY_HTML_TEMPLATES_FAIL";

export const UPDATE_QUERY_HTML_TEMPLATES_START =
  "UPDATE_QUERY_HTML_TEMPLATES_START";
export const UPDATE_QUERY_HTML_TEMPLATES_SUCCESS =
  "UPDATE_QUERY_HTML_TEMPLATES_SUCCESS";
export const UPDATE_QUERY_HTML_TEMPLATES_FAIL =
  "UPDATE_QUERY_HTML_TEMPLATES_FAIL";

export const DELETE_QUERY_HTML_TEMPLATES_START =
  "DELETE_QUERY_HTML_TEMPLATES_START";
export const DELETE_QUERY_HTML_TEMPLATES_SUCCESS =
  "DELETE_QUERY_HTML_TEMPLATES_SUCCESS";
export const DELETE_QUERY_HTML_TEMPLATES_FAIL =
  "DELETE_QUERY_HTML_TEMPLATES_FAIL";

export const PRINT_QUERY_HTML_TEMPLATE_FORM_START =
  "PRINT_QUERY_HTML_TEMPLATE_FORM_START";
export const PRINT_QUERY_HTML_TEMPLATE_FORM_SUCCESS =
  "PRINT_QUERY_HTML_TEMPLATE_FORM_SUCCESS";
export const PRINT_QUERY_HTML_TEMPLATE_FORM_FAIL =
  "PRINT_QUERY_HTML_TEMPLATE_FORM_FAIL";
