import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteQueryHtmlTemplate,
  getQueryHtmlTemplates,
  saveQueryHtmlTemplate,
  printQueryHtmlTemplateForm,
} from "../../../store/actions/htmlTemplates";
import Flex from "../../../UI/Flex/Flex";
import TemplateList from "./TemplateList";
import TemplateEditor from "./TemplateEditor";
import TemplateTopSection from "./TemplateTopSection";
import Loading from "../../../UI/Loading/Loading";
import Refreshing from "../../../charts/BaseChart/Refreshing";
import useConfirmDelete from "../../../hooks/useConfirmDelete";
import {
  convertFiltersToConfigFormat,
  convertOverridesToConfigFormat,
} from "../../../Editors/BlockEdit/VisualizationGuiEditSection/convertFiltersToOperatorsMode";

export default function HtmlTemplates(props) {
  const dispatch = useDispatch();
  const { activeQuery, lastSettings } = props;

  const { loading, templates, printLoading } = useSelector(
    (state) => state.htmlTemplates
  );

  const defaultTemplate =
    templates?.find((template) => template.isDefault) ?? null;

  const [activeTemplate, setActiveTemplate] = useState(defaultTemplate);

  const confirm = useConfirmDelete(
    onDelete,
    "Do you want to delete this template?"
  );

  useEffect(() => {
    dispatch(getQueryHtmlTemplates(activeQuery.uuid));
  }, [activeQuery.uuid, dispatch]);

  useEffect(() => {
    if (defaultTemplate && templates && !activeTemplate) {
      setActiveTemplate(defaultTemplate);
    }
  }, [defaultTemplate, templates, activeTemplate]);

  // change name, content and isDefault
  function onTemlateInnerPropsChange(key, value) {
    setActiveTemplate({ ...activeTemplate, [key]: value });
  }

  function onAddNew() {
    setActiveTemplate({
      name: "Template " + ((templates ?? []).length + 1),
      content: "",
      isDefault: false,
    });
  }

  // drop after create/delete
  function dropState(forceDrop) {
    if (!activeTemplate.uuid || forceDrop) {
      setActiveTemplate(null);
    }
  }

  async function onSave() {
    await dispatch(
      saveQueryHtmlTemplate({ queryUuid: activeQuery.uuid, ...activeTemplate })
    ).unwrap();

    dropState();
  }

  async function onDelete() {
    await dispatch(
      deleteQueryHtmlTemplate(activeQuery.uuid, activeTemplate.uuid)
    ).unwrap();

    dropState(true);
  }

  function printForm() {
    // connect with data explorer filters and overrides (aggregations and etc.)
    const { filters, overrides } = lastSettings ?? {};
    const configFilters = convertFiltersToConfigFormat(filters ?? []);
    const configOverrides = convertOverridesToConfigFormat(
      overrides?.fields ?? []
    );

    dispatch(
      printQueryHtmlTemplateForm({
        queryUuid: activeQuery.uuid,
        templateName: defaultTemplate.name,
        chart: {
          filters: configFilters,
          overrides: configOverrides,
          limit: 50,
        },
      })
    );
  }

  return (
    <div style={{ fontSize: 12 }}>
      <Refreshing show={loading} comp={<Loading />} />

      <TemplateTopSection
        activeTemplate={activeTemplate}
        onAddNew={onAddNew}
        onTemlateInnerPropsChange={onTemlateInnerPropsChange}
        onSave={onSave}
        onDelete={confirm.setConfirming}
        printForm={printForm}
        printLoading={printLoading}
        defaultTemplate={defaultTemplate}
      />

      <br />

      <Flex justifyContent="space-between">
        <TemplateList
          templates={templates ?? []}
          setActiveTemplate={setActiveTemplate}
          activeTemplate={activeTemplate}
        />
        <TemplateEditor
          onTemlateInnerPropsChange={onTemlateInnerPropsChange}
          activeTemplate={activeTemplate}
        />
      </Flex>

      {confirm.confirming}
    </div>
  );
}
