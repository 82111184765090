import * as actionTypes from "../actions/actionTypes";
import { CLEAR_BLOCKS, MARK_BLOCKS_REFRESHING } from "../actions/actionTypes";
import { format } from "date-fns";
import {
  buildLocalRowExpandGroupedData,
  doMerge,
} from "../../utils/dataOptionsValues";
import produce from "immer";
import { matchingArrayKeysAndObject } from "../../utils/func";
import padEmptyDateValues from "../../utils/dates/padEmptyDateValues";
import { omit } from "lodash-es";
import { exportXlsx } from "../../utils/exportTableToExcel";
import { absoluteDate } from "../../utils/dates/dateFunc";
import { setDataUniqueRowUuid } from "./layoutReducer";

const initialState = {
  blocks: [],
  detailsTable: {
    loading: false,
    error: false,
  },
  message: null,
  loadBundleId: null,
  rowExpandedCharts: {},
  currentVisId: null,
  exportAllCharts: null,
  tableEditorPreviewChart: null,
  runOnQueryFiltersChecked: false,
  rowExpandedChartsHeight: {},
  lastExecSuccessTimestamp: null,
};

export const hideCharts = (newFilters, q) => {
  const activeFilters = newFilters.filter((f) => f.checked).map((f) => f.type);
  if (q.hideOnFilters) {
    if (q.hideOnFilters.find((hof) => activeFilters.find((af) => hof === af)))
      return true;
  }

  if (q.showOnFilters) {
    if (
      q.showOnFilters.find((sof) => !activeFilters.find((af) => af === sof))
    ) {
      return true;
    }
  }

  if (!q.mainFilterOverride || !q.filters) return false;

  return newFilters.length > 0
    ? !newFilters.some((nf) => {
        const filterable = q.filters.filter(
          (f) => q.globalFilterOverride || f.type === nf.type
        );
        return filterable.length > 0
          ? filterable.some((ft) => ft.value === nf.value)
          : true;
      })
    : false;
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_LOAD_BUNDLE:
      draft.blocks
        .filter((b) => !b.section)
        .forEach((b) => {
          b.charts.forEach((c) => {
            if (c.loaded) {
              c.refreshing = true;
            }
          });
        });
      draft.loadBundleId = action.loadBundleId;
      break;

    case actionTypes.EXECUTE_QUERY_START: {
      if (action.meta?.forPreview && draft.tableEditorPreviewChart) {
        draft.tableEditorPreviewChart = {
          ...draft.tableEditorPreviewChart,
          refreshing: true,
        };
      }
      break;
    }

    case actionTypes.EXECUTE_QUERY_SUCCESS: {
      if (action.slice !== "dashboard") return;
      const { data, meta } = action.results;
      const chart = action?.meta?.chart ?? action.chart;

      let tData = setDataUniqueRowUuid(data);

      if (chart?.localRowExpandGrouping) {
        const { mainData, rowExpandedCharts } = buildLocalRowExpandGroupedData(
          tData,
          chart,
          meta
        );

        tData = mainData;
        draft.rowExpandedCharts[chart.uuid] = rowExpandedCharts;
      }

      const matchParams = [
        ["blocks", "charts"],
        "visualizationId",
        action.visualizationId,
      ];

      if (action.meta?.forPreview) {
        draft.tableEditorPreviewChart = {
          ...chart,
          meta,
          data: tData,
          loading: false,
          refreshing: false,
        };
        return;
      }

      const matched = matchingArrayKeysAndObject(...matchParams)(draft.blocks);

      if (!matched) {
        return;
      }

      const { chartsKey, blocksKey } = matched;
      const shouldPadZero = draft.blocks[blocksKey].charts[chartsKey].padZero;

      const formattedData = padEmptyDateValues(
        tData,
        action.dateFilters,
        shouldPadZero
      );

      const updateChart = doMerge(
        draft.blocks,
        action.visualizationId,
        formattedData,
        action.cacheKey,
        meta
      );

      draft.blocks[blocksKey].charts[chartsKey] = {
        ...matched.obj,
        ...updateChart,
      };

      draft.lastExecSuccessTimestamp = new Date();
      break;
    }

    case actionTypes.EXECUTE_QUERY_FAIL: {
      if (action.slice !== "dashboard") return;

      if (action.meta?.forPreview) {
        draft.tableEditorPreviewChart = {
          ...action.meta.chart,
          error: action.error,
          loading: false,
          refreshing: false,
        };
        return;
      }

      const matchParams = [
        ["blocks", "charts"],
        "visualizationId",
        action.visualizationId,
      ];
      const matched = matchingArrayKeysAndObject(...matchParams)(draft.blocks);
      if (!matched) return;

      const restricted =
        action.error && action.error.message.includes("restricted");

      if (action.error?.message === "canceled") {
        return;
      } else {
        draft.blocks[matched.blocksKey].charts[matched.chartsKey] = {
          ...matched.obj,
          ...(action.error && { error: action.error, restricted }),
          data: [],
          loading: false,
          refreshing: false,
        };
      }
      break;
    }

    case actionTypes.SET_CHART_VISIBILITY: {
      const newFilters = action.filters.filter((f) => f.checked);
      draft.blocks.forEach((block) => {
        block.hidden =
          block.hide ||
          (block.charts[0] && hideCharts(newFilters, block.charts[0]));
      });

      break;
    }

    case actionTypes.LOAD_DASHBOARD_CONFIGURATION_SUCCESS: {
      const { name, blocks } = action.results;
      draft.name = name;
      draft.message = null;
      draft.blocks = blocks.map((block) => ({
        ...block,
        charts: block.section
          ? []
          : block.charts.map((chart) => ({
              ...chart,
              loading: true,
              data: [],
              error: null,
              hide: !chart.availableForTerms
                ? false
                : !chart.availableForTerms.find((t) => t === action.term),
            })),
      }));
      break;
    }

    case actionTypes.LOAD_DASHBOARD_FILE_CONFIGURATION_SUCCESS: {
      const { blocks } = action.results;
      draft.fileBlocks = blocks.map((block) => ({
        ...block,
        charts: block.charts.map((chart) => ({ ...chart, data: [] })),
      }));
      break;
    }

    case actionTypes.LOAD_DASHBOARD_CONFIGURATION_FAIL: {
      draft.message = action.message;
      draft.blocks = [];
      break;
    }

    case actionTypes.SHOW_ALL_TABLE_RECORDS_START: {
      draft.detailsTable = {
        data: action.dropPreviousData ? [] : draft.detailsTable.data, // on Filters change we need to drop prev data and pagination value
        meta: draft.detailsTable.meta,
        loading: action.dropPreviousData || !draft.detailsTable.data,
        isNextPageLoading: !!draft.detailsTable?.data,
        error: false,
      };
      break;
    }

    case actionTypes.SHOW_ALL_TABLE_RECORDS_SUCCESS: {
      const { data, meta } = action.results;

      draft.detailsTable = {
        loading: false,
        isNextPageLoading: false,
        data: [
          ...(draft.detailsTable.data || []),
          ...data.map((item) =>
            item.CloseDate
              ? {
                  ...item,
                  CloseDate: format(absoluteDate(item.CloseDate), "M/dd/yyy"),
                }
              : item
          ),
        ],
        meta,
      };
      break;
    }

    case actionTypes.SHOW_ALL_TABLE_RECORDS_FAIL: {
      draft.detailsTable = {
        data: draft.detailsTable.data,
        meta: draft.detailsTable.meta,
        isNextPageLoading: false,
        loading: false,
        error: true,
      };
      break;
    }

    case actionTypes.CLEAR_ALL_TABLE_RECORDS: {
      draft.detailsTable = { loading: false };
      break;
    }

    case actionTypes.DOWNLOAD_TABLE_DATA_START: {
      draft.downloading = {
        loading: true,
        error: null,
      };
      break;
    }

    case actionTypes.DOWNLOAD_TABLE_DATA_SUCCESS: {
      draft.downloading = {
        loading: false,
        error: null,
      };
      break;
    }

    case actionTypes.DOWNLOAD_TABLE_DATA_FAIL: {
      draft.downloading = {
        loading: false,
        error: "There was a problem with your download",
      };
      break;
    }

    case actionTypes.STOP_DASHBOARD_SYNC: {
      draft.loadBundleId = null;
      break;
    }

    case actionTypes.SET_DATE_TERM: {
      draft.blocks.forEach((block) => {
        block.charts.forEach((chart) => {
          chart.data = [];
          if (chart.availableForTerms) {
            chart.hide = !chart.availableForTerms.find(
              (t) => t === action.term
            );
          }
        });
      });
      break;
    }

    case actionTypes.ROW_EXPAND_EXEC_QUERY_START: {
      draft.rowExpandedCharts[action.visualizationId] = {
        ...action.chart,
        loading: !action.chart.loaded,
        refreshing: action.chart.loaded,
        filters: action.filters,
      };
      break;
    }

    case actionTypes.ROW_EXPAND_EXEC_QUERY_EXPORT_ALL_START: {
      draft.exportAllCharts = {
        ...action.chart,
        loading: !action.chart.loaded,
        refreshing: action.chart.loaded,
        meta: [],
        filters: action.filters,
      };
      break;
    }

    case actionTypes.ROW_EXPAND_EXEC_QUERY_SUCCESS: {
      draft.rowExpandedCharts[action.visualizationId] = {
        ...action.chart,
        data: setDataUniqueRowUuid(action.results.data),
        additionalData: action.results.data,
        loading: false,
        meta: action.results.meta,
        refreshing: false,
        loaded: true,
        filters: action.filters,
      };
      break;
    }

    case actionTypes.ROW_EXPAND_EXEC_QUERY_EXPORT_ALL_SUCCESS: {
      draft.exportAllCharts = {
        ...action.chart,
        data: action.results.data,
        loading: false,
        meta: action.results.meta,
        refreshing: false,
        loaded: true,
        filters: action.filters,
      };
      break;
    }

    case actionTypes.ROW_EXPAND_EXEC_QUERY_FAIL: {
      draft.rowExpandedCharts[action.visualizationId] = {
        ...action.chart,
        data: [],
        loading: false,
        ...(action.error && { error: action.error.message }),
        refreshing: false,
        loaded: true,
      };
      break;
    }

    case actionTypes.ROW_EXPAND_EXEC_QUERY_EXPORT_ALL_FAIL: {
      draft.exportAllCharts = {
        ...action.chart,
        data: [],
        loading: false,
        ...(action.error && { error: action.error.message }),
        refreshing: false,
        loaded: true,
      };
      break;
    }

    case actionTypes.CLEAR_EXPORT_ALL_OBJECT: {
      draft.exportAllCharts = null;
      break;
    }

    case actionTypes.HANDLE_TABLE_PAGINATION:
      draft.blocks.forEach((b) => {
        b.charts.forEach((c) => {
          if (c.loaded && action.chart.visualizationId === c.visualizationId) {
            c.refreshing = true;
          }
        });
      });
      break;

    case actionTypes.REMOVE_DEEPER_EXPANDED_ROWS:
      const {
        keys,
        currentVisId,
        nonConvertPercentsBack,
        title,
        activeTab,
        dateFilters,
        filters,
      } = action;

      if (keys) {
        draft.rowExpandedCharts = omit(draft.rowExpandedCharts, keys);
      }
      draft.currentVisId = currentVisId;
      draft.nonConvertPercentsBack = !nonConvertPercentsBack;

      // you need to wait until the extended row closes
      setTimeout(
        () =>
          exportXlsx(
            currentVisId,
            !nonConvertPercentsBack,
            title,
            activeTab,
            dateFilters,
            filters
          ),
        500
      );

      break;

    case actionTypes.UPDATE_VISUALIZATION_SUCCESS:
      draft.blocks = draft.blocks.map((block) => {
        if (block.uuid === action.blockUuid) {
          return {
            ...block,
            charts: block.charts.map((chart) => ({
              ...chart,
              ...action.results.data.settings,
            })),
          };
        }
        return block;
      });
      break;

    case CLEAR_BLOCKS: {
      draft.blocks = [];
      break;
    }

    case MARK_BLOCKS_REFRESHING: {
      for (const block of draft.blocks) {
        for (const chart of block.charts) {
          chart.refreshing = true;
        }
      }
      break;
    }

    case actionTypes.SET_LOCAL_DRILLDOWNS_DATA: {
      draft.rowExpandedCharts = action.rowExpandedCharts;
      break;
    }

    case actionTypes.REFRESH_POWER_EDITOR_PREVIEW:
      draft.tableEditorPreviewChart = null;
      break;

    case actionTypes.RUN_ON_QUERY_FILTERS_CHECKED:
      draft.runOnQueryFiltersChecked = action.checked;
      break;

    case actionTypes.SET_ROW_EXPANDED_CHARTS_HEIGHT:
      draft.rowExpandedChartsHeight[action.index] = action.height;
      break;

    default:
      return draft;
  }
});
