import {
  addDays,
  addMonths,
  addQuarters,
  addWeeks,
  addYears,
  differenceInDays,
  differenceInMonths,
  differenceInQuarters,
  differenceInWeeks,
  differenceInYears,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  format,
  parseISO,
  setYear,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subQuarters,
  subWeeks,
  subYears,
  isFirstDayOfMonth,
  isSameDay,
  parse,
  startOfYesterday,
} from "date-fns";
import {
  DATE_FILTER_MODE_RANGE_ONLY,
  DATE_RANGE_START_CUSTOM,
  rangeAndAggregationOptionsByValue,
  daysOfWeek,
  dateFiltersTypeDependency,
  DATE_RANGE_END_CUSTOM,
} from "../constants/constants";
import { absoluteDate } from "../dates/dateFunc";
import { orderBy } from "lodash-es";
import { apiEndpoint } from "../env";

const pageLoadDate = new Date();
export const pageLoadDateString = pageLoadDate.toISOString().slice(0, 10);

const isStagingEnv = apiEndpoint?.includes("staging");

export const fromToDateFormatter = (term, range = 3) => {
  const dateRange = { from: null, to: null };
  switch (term) {
    case "monthly": {
      dateRange.to = format(endOfMonth(Date.now()), "yyyy-MM-dd");
      dateRange.from = format(
        startOfMonth(subMonths(Date.now(), range)),
        "yyyy-MM-dd"
      );
      break;
    }

    case "quarterly": {
      dateRange.to = format(endOfQuarter(Date.now()), "yyyy-MM-dd");
      dateRange.from = format(
        startOfQuarter(subQuarters(Date.now(), range)),
        "yyyy-MM-dd"
      );
      break;
    }

    case "weekly": {
      dateRange.to = format(endOfWeek(Date.now()), "yyyy-MM-dd");
      dateRange.from = format(
        subWeeks(endOfWeek(Date.now()), range),
        "yyyy-MM-dd"
      );
      break;
    }

    case "daily": {
      dateRange.to = format(Date.now(), "yyyy-MM-dd");
      dateRange.from = format(subDays(Date.now(), range), "yyyy-MM-dd");
      break;
    }

    default: {
      dateRange.to = format(endOfYear(Date.now()), "yyyy-MM-dd");
      dateRange.from = format(
        startOfYear(subYears(Date.now(), range)),
        "yyyy-MM-dd"
      );
    }
  }

  return dateRange;
};

const returnEmpty = (dateFilters) => {
  if (
    dateFilters.globalMaximumDate &&
    dateFilters.globalMinimumDate &&
    dateFilters.rangeSettingsStart
  ) {
    return false;
  }

  return (
    !dateFilters ||
    (dateFilters.filterMode !== DATE_FILTER_MODE_RANGE_ONLY &&
      !dateFilters.filterCustomValues?.find((fcv) =>
        dateFiltersTypeDependency.includes(fcv)
      )) ||
    !dateFilters.rangeSettingsTerm ||
    (!Number.isInteger(dateFilters.rangeSettingsStart) &&
      dateFilters.rangeSettingsStart !== DATE_RANGE_START_CUSTOM) ||
    !Number.isInteger(dateFilters.rangeSettingsEnd)
  );
};

export const createDateOptions = (dateFilters) => {
  if (returnEmpty(dateFilters)) {
    return [];
  }

  const term = dateFilters.rangeSettingsTerm;

  const startDate = absoluteDate(
    dateFilters.rangeSettingsStart === DATE_RANGE_START_CUSTOM
      ? dateFilters.globalMinimumDate
      : startOfNowTermForOptions(dateFilters.rangeSettingsStart, term)
  );

  const endDate = absoluteDate(
    dateFilters.rangeSettingsEnd === DATE_RANGE_END_CUSTOM
      ? dateFilters.globalMaximumDate
      : startOfNowTermForOptions(dateFilters.rangeSettingsEnd, term)
  );

  const config = {
    monthly: {
      count: differenceInMonths(endDate, startDate) + 1,
      add: addMonths,
    },
    quarterly: {
      count: differenceInQuarters(endDate, startDate) + 1,
      add: addQuarters,
    },
    yearly: {
      count: differenceInYears(endDate, startDate) + 1,
      add: addYears,
    },
    weekly: {
      count: differenceInWeeks(endDate, startDate) + 1,
      add: addWeeks,
    },
    daily: {
      count: differenceInDays(endDate, startDate) + 1,
      add: addDays,
    },
  };

  return Array(config[dateFilters.rangeSettingsTerm].count)
    .fill(null)
    .reduce((acc, _, i) => {
      const date = config[dateFilters.rangeSettingsTerm].add(startDate, i);
      const value = format(date, "yyyy-MM-dd");
      const label = format(
        date,
        rangeAndAggregationOptionsByValue[dateFilters.rangeSettingsTerm]
          .displayFormat
      );

      return [...acc, { value, label }];
    }, []);
};

export const startOfNowTermForOptions = (range, term) => {
  const start = {
    monthly: startOfMonth,
    quarterly: startOfQuarter,
  };
  const fn = start[term] || startOfQuarter;
  const d = fn(Date.now());
  return format(
    range >= 0 ? addMonths(d, range) : subMonths(d, -range),
    "yyyy-MM-dd"
  );
};

export const dateRangeType = {
  RM: "rm",
  MONTHLY: "monthly",
  QUARTERLY: "quarterly",
  YEARLY: "yearly",
  WEEK_TO_DATE: "wtd",
  LAST_5_WEEKS: "last5Weeks",
  MONTH_TO_DATE: "mtd",
  QUARTER_TO_DATE: "qtd",
  YEAR_TO_DATE: "ytd",
  YEAR_TO_COMPLETED_MONTH: "ytdCompletedMonth",
  CURRENT_SCHOOL_YEAR: "csy",
  PREVIOUS_SCHOOL_YEAR: "psy",
  LAST_TWELVE_MONTHS: "ltm",
  YESTERDAY: "yesterday",
  TODAY: "today",
  LAST_WEEK: "lw",
  NINETY_DAYS: "90days",
  SINGLE_QUARTER: "singleQuarter",
  ONE_YEAR: "oneYear",
  PREVIOUS_YEAR_TO_DATE: "previousYearToDate",
  TWO_YEARS_AGO_TO_DATE: "twoYearsAgoToDate",
  CURRENT_PREVIOUS_YEAR: "currPrevYear",
  CURRENT_PREVIOUS_TWO_YEARS_AGO: "currPrevTwoYearsAgo",
  NONE: "none",
  THIS_YEAR: "thisYear",
  THIS_QUARTER: "thisQuarter",
  THIS_YEAR_TO_END_OF_QUARTER: "thisYearToEndOfQuarter",
  CURRENT_MONDAY_TO_SUNDAY: "currentMondayToSunday",
  THIS_MONTH: "thisMonth",
  NEXT_MONTH: "nextMonth",
  NEXT_QUARTER: "nextQuarter",
  NEXT_13_WEEKS: "next13weeks",
  LAST_MONTH: "lastMonth",
  SINGLE_DAY: "sd",
  SINGLE_FISCAL_QUARTER: "singleFiscalQuarter",
  RQ: "rq",
  RY: "ry",
  FISCAL_QUARTER_RANGE: "fiscalQuarterRange",
  RW: "rw",
};

export const dateConfig = {
  defaultDateString: pageLoadDateString,
  defaultDate: pageLoadDate,
  setDefaultDate(date) {
    this.defaultDateString = format(date, "yyyy-MM-dd");
    this.defaultDate = date;
  },
};

/**
 * Gets the current date (when the page was first loaded), or potentially a
 * stubbed date for tests.
 *
 * @returns {Date}
 */
function getDate() {
  return dateConfig.defaultDate;
}

export const setDateFilters = (
  domain,
  term,
  rangeSettingsRange = 0,
  start = dateConfig.defaultDateString,
  end = dateConfig.defaultDateString,
  config = {},
  dateFilters
) => {
  function applyTodayOffset() {
    return domain === "ccg" ? setSpecificTimezone() : end;
  }

  function getUserUpdatedValuesOnTabChanged() {
    return [dateFilters?.start?.value, dateFilters?.end?.value];
  }

  switch (term) {
    case dateRangeType.RM: {
      const approved = config.approved?.rm;
      const [start, end] = getUserUpdatedValuesOnTabChanged();

      if (approved) {
        const approvedMonth = new parse(approved, "MMMM-yyyy", getDate());

        return {
          from: start || format(startOfMonth(approvedMonth), "yyyy-MM-dd"),
          to: end || format(endOfMonth(approvedMonth), "yyyy-MM-dd"),
        };
      }

      const currentMonth = getDate();

      return {
        from:
          start ||
          format(startOfMonth(subMonths(currentMonth, 1)), "yyyy-MM-dd"),
        to: end || format(endOfMonth(subMonths(currentMonth, 1)), "yyyy-MM-dd"),
      };
    }

    case dateRangeType.MONTHLY: {
      return {
        from: format(startOfMonth(parseISO(start)), "yyyy-MM-dd"),
        to: format(endOfMonth(parseISO(end)), "yyyy-MM-dd"),
      };
    }

    case dateRangeType.QUARTERLY: {
      return {
        from: format(startOfQuarter(parseISO(start)), "yyyy-MM-dd"),
        to: format(endOfQuarter(parseISO(end)), "yyyy-MM-dd"),
      };
    }

    case dateRangeType.YEARLY: {
      return {
        from:
          format(startOfYear(parseISO(start)), "yyyy-MM-dd") +
          "T06:00:00.000000Z",
        to:
          format(endOfYear(parseISO(end)), "yyyy-MM-dd") + "T06:00:00.000000Z",
      };
    }
    case dateRangeType.WEEK_TO_DATE: {
      return {
        from: format(startOfWeek(parseISO(start)), "yyyy-MM-dd"),
        to: format(parseISO(applyTodayOffset()), "yyyy-MM-dd"),
      };
    }

    case dateRangeType.LAST_5_WEEKS: {
      return {
        from: format(startOfWeek(subWeeks(parseISO(start), 5)), "yyyy-MM-dd"),
        to: format(endOfWeek(subWeeks(parseISO(start), 1)), "yyyy-MM-dd"),
      };
    }

    case dateRangeType.MONTH_TO_DATE: {
      function applyStartTodayOffset() {
        const isFirstOfMonth = isFirstDayOfMonth(absoluteDate(end));
        if (domain === "ccg" && isFirstOfMonth) {
          return parseISO(subMonths(absoluteDate(start), 1).toISOString());
        }

        return startOfMonth(parseISO(start));
      }

      const appliedStartToday = applyStartTodayOffset();
      const appliedToday = applyTodayOffset();

      return {
        from: format(appliedStartToday, "yyyy-MM-dd"),
        to: format(parseISO(appliedToday), "yyyy-MM-dd"),
      };
    }

    case dateRangeType.QUARTER_TO_DATE: {
      function applyStartTodayOffset() {
        const isDateSameDay = isSameDay(
          startOfQuarter(absoluteDate(end)),
          absoluteDate(end)
        );
        if (domain === "ccg" && isDateSameDay) {
          return parseISO(
            startOfQuarter(subQuarters(absoluteDate(start), 1)).toISOString()
          );
        }

        return startOfQuarter(absoluteDate(start));
      }

      return {
        from: format(applyStartTodayOffset(), "yyyy-MM-dd"),
        to: format(parseISO(applyTodayOffset()), "yyyy-MM-dd"),
      };
    }

    case dateRangeType.YEAR_TO_DATE: {
      return {
        from: format(startOfYear(parseISO(start)), "yyyy-MM-dd"),
        to: format(parseISO(applyTodayOffset()), "yyyy-MM-dd"),
      };
    }
    case dateRangeType.YEAR_TO_COMPLETED_MONTH: {
      const date = getDate();
      const endDate = endOfMonth(subMonths(date, 1));
      const startDate = startOfYear(endDate);
      return {
        from: format(startDate, "yyyy-MM-dd"),
        to: format(endDate, "yyyy-MM-dd"),
      };
    }
    // Current school year which starts with september
    case dateRangeType.CURRENT_SCHOOL_YEAR: {
      const date = getDate();
      const currentYear = date.getFullYear();
      const thisYearSeptember = new Date(currentYear, 8, 1);
      const prevYearSeptember = new Date(currentYear - 1, 8, 1);

      const start =
        thisYearSeptember.getTime() < date.getTime()
          ? thisYearSeptember
          : prevYearSeptember;

      return {
        from: format(start, "yyyy-MM-dd"),
        to: format(parseISO(applyTodayOffset()), "yyyy-MM-dd"),
      };
    }
    // Prev school year which starts with september prev year
    case dateRangeType.PREVIOUS_SCHOOL_YEAR: {
      const date = getDate();
      const currentYear = date.getFullYear();
      const yearOffset =
        new Date(currentYear, 8, 1).getTime() < date.getTime() ? 1 : 2;
      const start = new Date(currentYear - yearOffset, 8, 1);
      const end = new Date(currentYear, 7, 31);

      return {
        from: format(start, "yyyy-MM-dd"),
        to: format(end, "yyyy-MM-dd"),
      };
    }

    case dateRangeType.LAST_TWELVE_MONTHS: {
      return {
        to: format(endOfMonth(subMonths(getDate(), 1)), "yyyy-MM-dd"),
        from: format(startOfMonth(subMonths(getDate(), 12)), "yyyy-MM-dd"),
      };
    }

    case dateRangeType.YESTERDAY: {
      const date = subDays(getDate(), 1);
      const d = new Date();

      if (domain === "ccg") {
        date.setUTCHours(d.getUTCHours() - 5);
      }

      return {
        from: format(date, "yyyy-MM-dd"),
        to: format(date, "yyyy-MM-dd"),
      };
    }

    case dateRangeType.TODAY: {
      const date = getDate();
      const d = new Date();

      if (domain === "ccg") {
        date.setUTCHours(d.getUTCHours() - 5);
      }

      return {
        from: format(date, "yyyy-MM-dd"),
        to: format(date, "yyyy-MM-dd"),
      };
    }

    case dateRangeType.LAST_WEEK: {
      const lastWeekDate = getLastWeekDate();

      return {
        from: format(startOfWeek(lastWeekDate), "yyyy-MM-dd"),
        to: format(endOfWeek(lastWeekDate), "yyyy-MM-dd"),
      };
    }

    case dateRangeType.NINETY_DAYS: {
      return {
        from: format(subMonths(parseISO(start), 3), "yyyy-MM-dd"),
        to: format(parseISO(end), "yyyy-MM-dd"),
      };
    }

    case dateRangeType.SINGLE_QUARTER: {
      return {
        from: format(
          startOfQuarter(subQuarters(parseISO(start), rangeSettingsRange)),
          "yyyy-MM-dd"
        ),
        to: format(endOfQuarter(parseISO(end)), "yyyy-MM-dd"),
      };
    }

    case dateRangeType.ONE_YEAR:
      return {
        from: format(subYears(parseISO(start), 1), "yyyy-MM-dd"),
        to: format(parseISO(applyTodayOffset()), "yyyy-MM-dd"),
      };

    case dateRangeType.PREVIOUS_YEAR_TO_DATE:
      return {
        to: format(getDate(), "yyyy-MM-dd"),
        from: format(startOfYear(subYears(getDate(), 1)), "yyyy-MM-dd"),
      };

    case dateRangeType.TWO_YEARS_AGO_TO_DATE:
      return {
        to: format(getDate(), "yyyy-MM-dd"),
        from: format(startOfYear(subYears(getDate(), 2)), "yyyy-MM-dd"),
      };

    case dateRangeType.CURRENT_PREVIOUS_YEAR:
      return {
        to: format(endOfYear(getDate()), "yyyy-MM-dd"),
        from: format(startOfYear(subYears(getDate(), 1)), "yyyy-MM-dd"),
      };

    case dateRangeType.CURRENT_PREVIOUS_TWO_YEARS_AGO:
      return {
        to: format(endOfYear(getDate()), "yyyy-MM-dd"),
        from: format(startOfYear(subYears(getDate(), 2)), "yyyy-MM-dd"),
      };

    case dateRangeType.NONE:
      return {
        from: null,
        to: null,
      };

    case dateRangeType.THIS_YEAR:
      return {
        from: format(startOfYear(new Date()), "yyyy-MM-dd"),
        to: format(endOfYear(new Date()), "yyyy-MM-dd"),
      };

    case dateRangeType.THIS_QUARTER:
      return {
        from: format(startOfQuarter(new Date()), "yyyy-MM-dd"),
        to: format(endOfQuarter(new Date()), "yyyy-MM-dd"),
      };

    case dateRangeType.THIS_YEAR_TO_END_OF_QUARTER:
      return {
        from: format(startOfYear(new Date()), "yyyy-MM-dd"),
        to: format(endOfQuarter(new Date()), "yyyy-MM-dd"),
      };

    case dateRangeType.CURRENT_MONDAY_TO_SUNDAY:
      return {
        from: format(
          startOfWeek(new Date(), { weekStartsOn: 1 }),
          "yyyy-MM-dd"
        ),
        to: format(endOfWeek(new Date(), { weekStartsOn: 1 }), "yyyy-MM-dd"),
      };

    case dateRangeType.THIS_MONTH:
      return {
        from: format(startOfMonth(new Date()), "yyyy-MM-dd"),
        to: format(endOfMonth(new Date()), "yyyy-MM-dd"),
      };

    case dateRangeType.NEXT_MONTH:
      return {
        from: format(addMonths(startOfMonth(new Date()), 1), "yyyy-MM-dd"),
        to: format(addMonths(endOfMonth(new Date()), 1), "yyyy-MM-dd"),
      };

    case dateRangeType.NEXT_QUARTER:
      return {
        from: format(addQuarters(startOfQuarter(new Date()), 1), "yyyy-MM-dd"),
        to: format(addQuarters(endOfQuarter(new Date()), 1), "yyyy-MM-dd"),
      };

    case dateRangeType.NEXT_13_WEEKS: {
      const start = startOfWeek(new Date(), { weekStartsOn: 1 });
      const end = addWeeks(endOfWeek(new Date(), { weekStartsOn: 1 }), 13);

      return {
        from: format(start, "yyyy-MM-dd"),
        to: format(end, "yyyy-MM-dd"),
      };
    }

    case dateRangeType.LAST_MONTH: {
      const from = startOfMonth(subMonths(parseISO(start), 1));
      const to = endOfMonth(subMonths(parseISO(start), 1));

      return {
        from: format(from, "yyyy-MM-dd"),
        to: format(to, "yyyy-MM-dd"),
      };
    }

    case dateRangeType.RY:
    case dateRangeType.RQ:
    case dateRangeType.RW:
    case dateRangeType.SINGLE_DAY:
    case dateRangeType.FISCAL_QUARTER_RANGE:
    case dateRangeType.SINGLE_FISCAL_QUARTER: {
      const [from, to] = getUserUpdatedValuesOnTabChanged();

      return {
        from: from || format(parseISO(start), "yyyy-MM-dd"),
        to: to || format(parseISO(end), "yyyy-MM-dd"),
      };
    }

    default: {
      return {
        from: format(parseISO(start), "yyyy-MM-dd"),
        to: format(parseISO(end), "yyyy-MM-dd"),
      };
    }
  }
};

export const getRecentMonths = ({
  valueFormat,
  labelFormat,
  isReverse,
  count,
  now = getDate(),
  hardcodedPinnacle,
}) => {
  const date = addMonths(startOfMonth(now), count + 1);
  const ignore = [];
  const { activeTab, domain } = hardcodedPinnacle ?? {};

  // remove when Pinnacle is ready
  const hide =
    domain === "pinnacle" && activeTab?.slug?.includes(pagesWithExcelence);

  const options = Array(Math.abs(count))
    .fill(null)
    .reduce((acc, _, i) => {
      return [
        ...acc,
        {
          from: format(addMonths(date, i), valueFormat),
          to: format(endOfMonth(addMonths(date, i)), valueFormat),
          label: format(addMonths(date, i), labelFormat),
        },
      ];
    }, [])
    .filter((option) => {
      if (!isStagingEnv && hide) {
        return !ignore.includes(option.label);
      }

      return true;
    });

  return isReverse ? options.reverse() : options;
};

export const getRecentAndFutureMonths = (now = pageLoadDateString) => {
  const today = parseISO(now);
  const result = [];

  // Start from one year before the current month
  let currentDate = addMonths(today, -12);

  // Iterate through 25 months (12 months back + current month + 12 months forward)
  for (let i = 0; i < 25; i++) {
    const from = format(startOfMonth(currentDate), "yyyy-MM-dd"); // Start of the month
    const to = format(endOfMonth(currentDate), "yyyy-MM-dd"); // End of the month
    const currentMonth = format(startOfMonth(today), "yyyy-MM-dd");

    result.push({
      from,
      to,
      label: format(currentDate, "MMMM-yyyy"),
      isCurrentDate: currentMonth === from,
    });

    // Move to the next month
    currentDate = addMonths(currentDate, 1);
  }

  return result;
};

// TODO: not sure we remove this hardcoded things but hope so
const allowedCCGQuarters = ["Q1-2024", "Q2-2024", "Q3-2024"];
const allowedTabs = ["fitter-scorecard"];

export const getRecentQuarters = (
  valueFormat,
  labelFormat,
  isReverse,
  count,
  now = getDate(),
  hardcoded // not only pinncale but ccg also
) => {
  const date = addQuarters(startOfQuarter(now), count + 1);

  const options = Array(Math.abs(count))
    .fill(null)
    .reduce((acc, _, i) => {
      return [
        ...acc,
        {
          from: format(addQuarters(date, i), valueFormat),
          to: format(endOfQuarter(addQuarters(date, i)), valueFormat),
          label: format(addQuarters(date, i), labelFormat),
        },
      ];
    }, []);

  const { activeTab, domain } = hardcoded;

  if (domain === "ccg" && allowedTabs.includes(activeTab?.slug)) {
    const filtered = options.filter((option) =>
      allowedCCGQuarters.includes(option.label)
    );
    return isReverse ? filtered.reverse() : filtered;
  }

  return isReverse ? options.reverse() : options;
};

export function getRecentWeeks(count = 26, now = getDate(), weekStartsOn = 0) {
  const date = subWeeks(startOfWeek(now, { weekStartsOn }), +count);
  const valueFormat = "yyyy-MM-dd";
  const labelFormat = "MMM d";
  const isReverse = true;

  const options = Array(+count)
    .fill(null)
    .reduce((acc, _, i) => {
      const from = addWeeks(date, i);
      const to = endOfWeek(addWeeks(date, i), { weekStartsOn });
      return [
        ...acc,
        {
          from: format(from, valueFormat),
          to: format(to, valueFormat),
          label: `${format(from, labelFormat)} - ${format(to, labelFormat)}`,
        },
      ];
    }, []);

  return isReverse ? options.reverse() : options;
}

export function getRecentYears(startYear = 2018, now = getDate()) {
  const endYear = now.getFullYear();
  const count = -(endYear - startYear + 1);
  const date = startOfYear(setYear(now, startYear));
  const valueFormat = "yyyy-MM-dd";
  const labelFormat = "yyyy";
  const isReverse = true;

  const options = Array(Math.abs(count))
    .fill(null)
    .reduce((acc, _, i) => {
      const from = addYears(date, i);
      const to = endOfYear(addYears(date, i));
      return [
        ...acc,
        {
          from: format(from, valueFormat),
          to: format(to, valueFormat),
          label: format(from, labelFormat),
        },
      ];
    }, []);

  return isReverse ? options.reverse() : options;
}

export function getRecentYearsCustom() {
  return [
    {
      label: "Current Year",
      specialLabel: new Date().getFullYear().toString(),
    },
    {
      label: "Last Year",
      specialLabel: (new Date().getFullYear() - 1).toString(),
    },
  ];
}

const getLastWeekDate = () => {
  const date = getDate();
  return date.setDate(date.getDate() - 7);
};

export function getDaysOfWeek() {
  return daysOfWeek.map((dayOfWeek) => ({
    from: dayOfWeek,
    to: dayOfWeek,
    label: dayOfWeek,
  }));
}

export function getSingleQuarters(dateFilter) {
  return createDateOptions(dateFilter).map((date) => ({
    from: format(startOfQuarter(parseISO(date.value)), "yyyy-MM-dd"),
    to: format(endOfQuarter(parseISO(date.value)), "yyyy-MM-dd"),
    label: date.label,
  }));
}

const pagesWithExcelence = "-excellence";
const ignore = [
  "Q2 2025",
  "Q3 2025",
  "Q4 2025",
  "Q1 2026",
  "Q2 2026",
  "Q3 2026",
];

export function getSingleFiscalQuarters(dateFilter, hardcodedPinnacle) {
  // remove this when pinnacle Q2 2022 will be ready
  const { fiscalQuarterStartMonth } = dateFilter;
  const { activeTab, domain } = hardcodedPinnacle ?? {};

  if (!fiscalQuarterStartMonth) {
    return [];
  }

  const start = startOfYear(new Date(fiscalQuarterStartMonth));
  const end = endOfYear(getDate());
  const count = differenceInQuarters(end, start) + 1;

  const options = Array(count)
    .fill("")
    .map((_, index) => {
      const date = addMonths(start, 3 * index);
      const quarter = format(date, "Q");

      return {
        from: format(startOfQuarter(date), "yyyy-MM-dd"),
        to: format(endOfQuarter(date), "yyyy-MM-dd"),
        label: `Q${quarter} ${date.getFullYear()}`,
        index,
      };
    });

  // remove this when pinnacle Q2 2022 will be ready
  const hide =
    domain === "pinnacle" && activeTab?.slug?.includes(pagesWithExcelence);
  // remove this when pinnacle Q2 2022 will be ready
  return options.filter((option) => {
    if (hide && !isStagingEnv) {
      return !ignore.includes(option.label);
    }

    return true;
  });
}

export function getFullWeekDateRange(includeFullWeek, dateFilters) {
  const { start, end } = dateFilters;

  // if !includeFullWeek we will use user selected Date
  if (!includeFullWeek || !start?.value || !end?.value) {
    return dateFilters;
  }

  return {
    start: {
      ...start,
      value: format(startOfWeek(parseISO(start.value)), "yyyy-MM-dd"),
    },
    end: {
      ...end,
      value: format(endOfWeek(parseISO(end.value)), "yyyy-MM-dd"),
    },
  };
}

export function getLastWeekRange() {
  return {
    start: format(startOfWeek(getLastWeekDate()), "yyyy-MM-dd"),
    end: format(endOfWeek(getLastWeekDate()), "yyyy-MM-dd"),
  };
}

/**
 * Offsets a local date based on the passed timezone (taking DST into account).
 * The resulting date is also in the local date format like the passed date, so
 * it can only be used for operations that are agnostic of the timezone.
 *
 * @param date {Date}
 * @param timezone {string} E.g. "America/New_York"
 * @returns {Date}
 */
export function offsetDateByTimezone(date, timezone) {
  const withTimezone = date.toLocaleString("en-US", {
    timeZone: timezone,
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hourCycle: "h23",
  });

  return new Date(convertUSDateStringToISODate(withTimezone));
}

/**
 * Converts an en-US locale date string into an ISO string.
 * NOTE: the resulting string does not contain any timezone information.
 *  Constructing a new Date with it will be in the user's local timezone.
 *
 * @param usDateString {string}
 * @returns {string}
 */
export function convertUSDateStringToISODate(usDateString) {
  const s = usDateString.split(/\D+/);
  return `${s[2]}-${s[0]}-${s[1]}T${s[3]}:${s[4]}:${s[5]}`;
}

/**
 * Returns New York's yesterday's date string.
 *
 * @param date {Date?}
 * @returns {string}
 */
export const setSpecificTimezone = (date = getDate()) => {
  const newDate = addDays(offsetDateByTimezone(date, "America/New_York"), -1);

  return format(newDate, "yyyy-MM-dd");
};

export const getWeekNameByWeekRange = (weekRange = "", weekOffset) => {
  // const date = weekRange.split("-")[0];
  // const diff = differenceInWeeks(new Date(date), startOfWeek(getNow()));
  // We were checking by diff above earlier
  switch (weekOffset) {
    case -2:
      return weekRange + " - Two Weeks Prior";
    case -1:
      return weekRange + " - Last Week";

    case 0:
      return weekRange + " - Current Week";

    case 1:
      return weekRange + " - Next Week";

    case 2:
      return weekRange + " - Two Weeks Out";

    default:
      return weekRange;
  }
};
export const getWeekNameByWeekRangeList = (weekRange = "", weekOffset) => {
  if (weekOffset === 0) {
    return `Current Week \n ${weekRange}`;
  }

  const absOffset = Math.abs(weekOffset);
  const direction = weekOffset > 0 ? "Out" : "Prior";
  const weekLabel = absOffset === 1 ? "1 Week" : `${absOffset} Weeks`;

  return `${weekLabel} ${direction} \n ${weekRange}`;
};

export function isDate(date) {
  return date && new Date(date) !== "Invalid Date" && !isNaN(new Date(date));
}

export function getFiscalYear(date, monthOffset = 0) {
  const simpleDateString = convertFromUSLocale(date);
  const toDate = parseISO(simpleDateString);

  const month = toDate.getMonth();
  const year = toDate.getFullYear();

  const yearOffset = Math.floor((month - (monthOffset % 12 || 12)) / 12) + 1;
  return yearOffset + year;
}

function convertFromUSLocale(str) {
  const dateArr = str.split("/");

  if (dateArr.length === 3) {
    const [month, day, year] = dateArr;
    return `${year}-${month}-${day}`;
  }

  return str;
}

const getApprovedPeriod = (options, dateType, dateFiltersConfig) => {
  const { approved, defaultFiscalQuarter } = dateFiltersConfig;

  if ((!approved || !approved[dateType]) && !defaultFiscalQuarter) {
    return 1;
  }

  const index = options.findIndex(
    (o) =>
      (approved && o.label === approved[dateType]) ||
      o.label === defaultFiscalQuarter
  );

  return index > -1 ? index : 1;
};

export const setDynamicDateOption = ({
  options,
  dateType,
  dateFilterSettings = {},
  dateFiltersConfig = {},
}) => {
  const specialGroup = [
    "rm",
    "ry",
    "rq",
    "singleFiscalQuarter",
    "fiscalQuarterRange",
  ];

  const { startDate, endDate, term } = dateFilterSettings ?? {};

  if (dateType === "rfm") {
    return options.find((option) => option.isCurrentDate) ?? options[0];
  }

  if (specialGroup.includes(dateType)) {
    const index = getApprovedPeriod(options, dateType, dateFiltersConfig);
    return options[index];
  }

  if (term === dateType) {
    // for recent years/quarters/months/weeks use always last option even for saved date period
    if (["rm", "ry", "rq", "rw"].includes(term)) {
      return options[0];
    }

    return options.find(
      (option) => option.from === startDate && option.to === endDate
    );
  }

  return options[0];
};

export const getCustomRangeDates = () => {
  const yesterday = startOfYesterday();
  const firstDayOfMonth = startOfMonth(yesterday);

  return {
    start: format(firstDayOfMonth, "yyyy-MM-dd"),
    end: format(yesterday, "yyyy-MM-dd"),
  };
};

const currentDate = new Date();
export const getLatestCompleteTenTwoWeeks = (approved = {}) => {
  // use approved or current date
  const startDate = new Date(
    approved.biweeklySchedule ?? addDays(currentDate, 1)
  ).toISOString();

  // approved 10 biweekly periods
  const quantity = 10;

  // generate future periods array from approved start date
  const future = generateFutureBiweeklyPeriods(startDate);

  // get difference between future periods and approved quantity
  const restQuantity = quantity - future.length;

  // // generate past periods array from approved start date
  const past = generatePastBiweeklyPeriods(startDate, restQuantity);

  // return reversed date periods array
  return orderBy([...past, ...future], "from", "desc");
};

// build period object
function buildPeriod(start, end) {
  return {
    from: format(start, "yyyy-MM-dd"),
    to: format(end, "yyyy-MM-dd"),
    label: `${format(start, "MM/dd/yyyy")} - ${format(end, "MM/dd/yyyy")}`,
  };
}

// go to past
function generatePastBiweeklyPeriods(startDate, count) {
  const periods = [];

  let currentPeriodStart = subDays(absoluteDate(startDate), 1);

  for (let i = 0; i < count; i++) {
    const end = currentPeriodStart;
    const start = addDays(subWeeks(end, 2), 1);
    periods.push(buildPeriod(start, end));
    currentPeriodStart = subWeeks(currentPeriodStart, 2);
  }

  return periods;
}

// go to future
function generateFutureBiweeklyPeriods(startDate) {
  const periods = [];

  let currentPeriodStart = absoluteDate(startDate);

  while (addWeeks(currentPeriodStart, 2) < currentDate) {
    const start = currentPeriodStart;
    const end = subDays(addWeeks(start, 2), 1);
    periods.push(buildPeriod(start, end));
    currentPeriodStart = addWeeks(currentPeriodStart, 2);
  }

  return periods;
}

function checkDays(days) {
  if (!days || isNaN(+days)) {
    return 0;
  }

  return days - 1;
}

export function getLastDaysNoTodayDateRange(days) {
  const now = dateConfig.defaultDateString;
  const yesterday = subDays(now, 1);
  const count = checkDays(days);

  return {
    end: format(yesterday, "yyyy-MM-dd"),
    start: format(subDays(yesterday, count), "yyyy-MM-dd"),
  };
}

export function getLastDaysDateRange(days) {
  const now = dateConfig.defaultDateString;
  const count = checkDays(days);

  return {
    end: format(now, "yyyy-MM-dd"),
    start: format(subDays(now, count), "yyyy-MM-dd"),
  };
}

export function getNextDaysDateRange(days) {
  const now = dateConfig.defaultDateString;
  const count = checkDays(days);

  return {
    start: format(now, "yyyy-MM-dd"),
    end: format(addDays(now, count), "yyyy-MM-dd"),
  };
}

export function getPastYearsToNextDaysDateRange(days, years = 3) {
  const now = dateConfig.defaultDateString;
  const count = checkDays(days);

  return {
    start: format(subYears(now, years), "yyyy-MM-dd"),
    end: format(addDays(now, count), "yyyy-MM-dd"),
  };
}

// this function set date presets as filter
export function buildFilters(from, to, name) {
  const createFilter = (value) => ({
    key: name,
    type: name,
    value,
    checked: true,
    label: value,
  });

  return [createFilter(from), createFilter(to)];
}

/**
 * Adjusts a date string to remove the timezone difference, returning a new Date object.
 *
 * @param {string} dateString - The date string to adjust.
 * @returns {Date} - A new Date object representing the same moment in time, adjusted to remove the timezone difference.
 */
export function getDateWithoutTimezoneDifference(dateString) {
  const date = new Date(dateString);
  return new Date(date.valueOf() + date.getTimezoneOffset() * 60 * 1000);
}
