import React, { useEffect, useState, useRef, useCallback } from "react";
import styled from "@emotion/styled";
import Select from "../../UI/Form/Select/Select";
import FilterGroup from "./FilterGroup";
import {
  customFilterOptionsByValue,
  dateFormats,
} from "../../utils/constants/constants";
import CustomDateFilters from "./ToDateFilters/CustomDateFilters";
import toDateFiltersOptions from "./ToDateFilters/toDateFiltersOptions";
// import RecentDateRangeQuantity from "./ToDateFilters/RecentDateRangeQuantity";
import { useDispatch, useSelector } from "react-redux";
import { setDynamicDateOption } from "../../utils/formatters/dateFormatter";
import { toDateOptions } from "./ToDateFilters/customDateUtils";
import { saveStickyDateType } from "../../store/actions/layout";
import useResetDateOptions from "./useResetDateOptions";
import { InfoCircleTooltip } from "../../UI/SimpleTooltip/SimpleTooltip";

const RecentPeriods = styled.div`
  margin-top: 24px;
`;

// Custom range date filter.
export default function ToDateFilters(props) {
  const {
    dateFiltersConfig,
    selectedDateType,
    setSelectedDateType,
    startDate,
    endDate,
    changeDateValue,
    setDate,
    recentMonthsChange,
    domain,
    userSettings,
    dateConfig,
    dropDateGroup,
    additionalTheme,
    onDateTypeChangeHandler,
  } = props;

  const { recentMonths, recentQuarters } =
    dateFormats[domain] ?? dateFormats.default;
  const dateType = selectedDateType?.value;
  const [recentSelected, setRecentSelected] = useState(null);
  const typeRef = useRef(null);
  const recentRef = useRef(null);
  const dispatch = useDispatch();

  // remove this when pinnacle Q2 2022 will be ready
  const activeTab = useSelector((state) => state.layout.activeTab);
  const hardcodedPinnacle = { activeTab, domain };

  const [selectProps, setSelectProps] = useState(
    toDateFiltersOptions(
      recentMonths,
      recentQuarters,
      dateFiltersConfig,
      null, // remove this when pinnacle Q2 2022 will be ready
      hardcodedPinnacle // remove this when pinnacle Q2 2022 will be ready
    )
  );

  const { needRefresh } = useResetDateOptions();

  // remove this when pinnacle Q2 2022 will be ready
  useEffect(() => {
    const options = toDateFiltersOptions(
      recentMonths,
      recentQuarters,
      dateFiltersConfig,
      undefined,
      hardcodedPinnacle
    );
    setSelectProps(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, needRefresh]);

  // special feature for pages
  // now we have opportunity to set different date filters on each page
  const mainOptions = toDateOptions(
    dateFiltersConfig.filterCustomValues,
    dateFormats[domain],
    dateFiltersConfig.pageOriented,
    activeTab,
    domain,
    additionalTheme
  );

  function getUserSelectedDateOption() {
    const { pageOriented } = dateFiltersConfig ?? {};

    return (pageOriented ?? []).find(({ pageUuid, pageUuids = [] }) =>
      [pageUuid, ...pageUuids].includes(activeTab?.uuid)
    )?.dateOption;
  }

  // set options based on date type change (Can we get this out of useEffect?)
  useEffect(() => {
    if (dateType) {
      const options = selectProps[dateType]?.options ?? [];

      const option = setDynamicDateOption({
        options,
        dateType,
        dateFilterSettings: userSettings?.dateFilterSettings,
        dateFiltersConfig,
      });

      const dateOption = getUserSelectedDateOption() ?? option;

      if (dateOption) {
        setRecentSelected(dateOption);
        recentMonthsChange(dateOption, selectedDateType);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateType, selectProps]);

  const getPosition = useCallback(({ current }) => {
    if (!current) {
      return "auto";
    }

    return window.innerHeight - current?.offsetTop + current?.offsetHeight > 250
      ? "bottom"
      : "top";
  }, []);

  const mainSelection = mainOptions.find((o) => o.value === dateType);

  // only for dates
  function updateDates(type) {
    if (customFilterOptionsByValue[type.value]?.hasParameter) {
      setSelectedDateType(type);
    } else {
      onDateTypeChangeHandler(type);
    }
    dispatch(
      saveStickyDateType({
        pageUuid: activeTab?.uuid,
        term: type,
        clearDateValue: true,
      })
    );
  }

  function handleMainTypeSelection(type) {
    // on date presets do not work as date filter, work as menu filter
    if (!dropDateGroup) {
      return updateDates(type);
    }

    setSelectedDateType(type);
  }

  function onRecentChange(option, isLocal) {
    if (isLocal) {
      setRecentSelected(option);
    }

    recentMonthsChange(option, selectedDateType);

    dispatch(
      saveStickyDateType({
        pageUuid: activeTab?.uuid,
        term: selectedDateType,
        dateOption: option,
      })
    );
  }

  const noneOption = mainOptions.find((o) => o.value === "none");
  const options = mainOptions.filter((o) => o.value !== "none");
  const currentNoneOption = dropDateGroup
    ? { value: null, label: "None..." }
    : noneOption;

  const formatOptionLabel = ({ label, period }) => (
    <div>
      <div>{label}</div>
      {period && <div>{period}</div>}
    </div>
  );

  const innerFilters = (
    <>
      {/*Main Option Select*/}
      {(!!mainOptions.length && (
        <div ref={typeRef}>
          <Select
            options={
              currentNoneOption ? [currentNoneOption, ...options] : options
            }
            classNamePrefix="react-select"
            onChange={handleMainTypeSelection}
            value={mainSelection}
            placeholder="Select date filter type..."
            menuPlacement={getPosition(typeRef)}
            menuHeight="fit-content"
            cy="select-custom-date"
            formatOptionLabel={formatOptionLabel}
          />
        </div>
      )) || (
        <div>
          <em>No date filter presets available</em>{" "}
          <InfoCircleTooltip tooltip="You can add date filter presets in Site Configuration -> Date Filters." />
        </div>
      )}

      {/*Second Option Selection*/}
      <CustomDateFilters
        startDate={startDate}
        endDate={endDate}
        setDate={setDate}
        changeDateValue={changeDateValue}
        dateType={dateType}
        RecentPeriods={RecentPeriods}
        dateFiltersConfig={dateFiltersConfig}
        getPosition={getPosition}
        recentMonthsChange={onRecentChange}
        dateConfig={dateConfig}
        domain={domain}
        selectProps={selectProps}
        getUserSelectedDateOption={getUserSelectedDateOption}
      />

      {/* <RecentDateRangeQuantity
        dateType={dateType}
        setSelectProps={setSelectProps}
        selectProps={selectProps}
      /> */}

      {/*@todo crush this*/}
      {selectProps[dateType] && (
        <RecentPeriods ref={recentRef}>
          <Select
            options={selectProps[dateType].options}
            onChange={(option) => onRecentChange(option, true)}
            getOptionValue={(o) => o.from}
            placeholder={selectProps[dateType].placeholder}
            value={recentSelected}
            menuPlacement={getPosition(recentRef)}
          />
        </RecentPeriods>
      )}
    </>
  );

  if (dropDateGroup) {
    return innerFilters;
  }

  return (
    <FilterGroup
      name={dateFiltersConfig?.displayName || "Dates"}
      open
      cy="left-menu-to-current-range"
    >
      {innerFilters}
    </FilterGroup>
  );
}
