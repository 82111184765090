import * as actions from "../actions/actionTypes";

const initialState = {
  loading: false,
  printLoading: false,
  templates: null,
};

export default function htmlTemplatesReducer(state = initialState, action) {
  switch (action.type) {
    case actions.CREATE_QUERY_HTML_TEMPLATES_START:
    case actions.UPDATE_QUERY_HTML_TEMPLATES_START:
    case actions.GET_QUERY_HTML_TEMPLATES_START:
    case actions.DELETE_QUERY_HTML_TEMPLATES_START:
      return {
        ...state,
        loading: true,
      };

    case actions.CREATE_QUERY_HTML_TEMPLATES_FAIL:
    case actions.UPDATE_QUERY_HTML_TEMPLATES_FAIL:
    case actions.GET_QUERY_HTML_TEMPLATES_FAIL:
    case actions.DELETE_QUERY_HTML_TEMPLATES_FAIL:
      return {
        ...state,
        loading: false,
      };

    case actions.GET_QUERY_HTML_TEMPLATES_SUCCESS:
      return {
        state,
        templates: action.results.data,
        loading: false,
      };

    case actions.CREATE_QUERY_HTML_TEMPLATES_SUCCESS:
      return {
        ...state,
        templates: [...state.templates, action.results.data],
        loading: false,
      };

    case actions.UPDATE_QUERY_HTML_TEMPLATES_SUCCESS:
      const updated = action.results.data;
      return {
        ...state,
        loading: false,
        templates: state.templates?.map((template) =>
          template.uuid === updated.uuid ? updated : template
        ),
      };

    case actions.DELETE_QUERY_HTML_TEMPLATES_SUCCESS:
      return {
        ...state,
        loading: false,
        templates: state.templates?.filter(
          (template) => template.uuid !== action.templateUuid
        ),
      };

    case actions.PRINT_QUERY_HTML_TEMPLATE_FORM_START:
      return {
        ...state,
        printLoading: true,
      };

    case actions.PRINT_QUERY_HTML_TEMPLATE_FORM_SUCCESS:
    case actions.PRINT_QUERY_HTML_TEMPLATE_FORM_FAIL:
      return {
        ...state,
        printLoading: false,
      };

    default:
      return state;
  }
}
