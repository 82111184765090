import produce from "immer";
import { omit, noop, orderBy } from "lodash-es";
import { v4 as uuid } from "uuid";
import * as queryString from "qs";
import * as actionTypes from "../actionTypes";
import { CLEAR_BLOCKS } from "../actionTypes";
import {
  defaultPage,
  recordsPerPage,
} from "../../../utils/constants/constants";
import { nestedPullMatched } from "../../../utils/func";
import axiosInstance from "../../../axios";
import queryBuilder from "../queryBuilder/queryBuilder";
import { handleError, reportError } from "../../../utils/errorHandling";
import axios from "axios";
import { hashQueryExecCall } from "../../../utils/crypto";
import { compareByOperator } from "../../../utils/constants/operators";
import { loadHeaderConfiguration } from "../layout";
import { removeDuplicateOverride } from "../../../Pages/TableEditor/utils/tableEditorHelper";

let execAbortController = new AbortController();

function reduceWithConditions(acc, curr, runQueryOnFilters = []) {
  const settings = runQueryOnFilters.find(
    (settings) => settings.name === curr.key
  );

  if (settings?.operator) {
    const { operator, value } = settings;
    if (compareByOperator(curr.value, value, operator)) {
      acc.push(curr);
    }
  } else {
    acc.push(curr);
  }

  return acc;
}

// Function to filter menu filters based on filters to be run
function getRunQueryFilters(menuFilters, configFilters, runQueryOnFilters) {
  const filters = menuFilters.filter((filter) =>
    runQueryOnFilters.map((settings) => settings.name).includes(filter.name)
  );

  // get runQueryOnFilters if they are in query filters array
  const appliedLocalFilters = (configFilters ?? [])
    .filter((filter) =>
      runQueryOnFilters.map((settings) => settings.name).includes(filter.type)
    )
    // map query filters to menuFilters structure
    .map((filter) => ({
      values: {
        value: filter.value,
        checked: true,
        key: filter.type,
        operator: filter.operator,
      },
    }));

  // Retrieve checked values from the filtered filters
  return getCheckedFilterValues(
    [...appliedLocalFilters, ...filters],
    runQueryOnFilters
  );
}

// Function will filter checked values if there is a condition in runQueryOnFilters item
function respectToConditions(selectedFilters, runQueryOnFilters) {
  return selectedFilters.reduce(
    (acc, curr) => reduceWithConditions(acc, curr, runQueryOnFilters),
    []
  );
}

// Function to get checked values from an array of filters
export function getCheckedFilterValues(filters, runQueryOnFilters) {
  const selectedFilters = filters
    .flatMap((filter) => filter.values)
    .filter((val) => val.checked);

  return respectToConditions(selectedFilters, runQueryOnFilters);
}

// Function to execute a query without making an API call
function executeQueryWithoutApiCall(
  dispatch,
  actionProperties,
  forPreview,
  query
) {
  dispatch({ type: actionTypes.RUN_ON_QUERY_FILTERS_CHECKED, checked: true });
  dispatch({
    type: actionTypes.EXECUTE_QUERY_SUCCESS,
    ...{
      ...actionProperties,
      meta: {
        forPreview,
        ...(forPreview && { chart: query }),
      },
      results: { data: [] },
    },
  });
}

export function executeQuery(
  query,
  remainingCharts,
  nextFunction = loadCharts,
  slice = "dashboard",
  loadBundleId,
  forPreview,
  forHeaderBlocks,
  page
) {
  return async (dispatch, getState) => {
    const editorLimit = forPreview && !query.limit ? 1000 : query.limit;

    const qs = queryBuilder(
      { ...query, limit: editorLimit },
      getState,
      false,
      "chart",
      {
        forHeaderBlocks,
      },
      page
    );
    const {
      convertGetToPost,
      inComparisonMode,
      runQueryOnFilters,
      filters,
      ignoreAbortSignal,
    } = query;

    const { dateFilters, comparisonMode, menuFilters } = getState().layout;
    const { checked, count } = comparisonMode ?? {};

    if (inComparisonMode && checked !== count && Number.isFinite(count)) {
      return;
    }

    if (!ignoreAbortSignal) {
      // Abort any previous request.
      execAbortController.abort();
    }
    const currentAbortController = (execAbortController =
      new AbortController());

    const api = {
      public: false,
      method: convertGetToPost ? "POST" : "GET",
      endpoint: `api/v1/queries/${query["queryId"]}/exec?${
        convertGetToPost ? "" : qs
      }`,
      ...(!ignoreAbortSignal && { signal: execAbortController.signal }),
      reportAllErrors: true,
      ...(convertGetToPost && {
        payload: queryString.parse(qs, { depth: 10 }), // for the nested array parsing we need set depth as option
      }),
    };

    let cacheKey;
    try {
      cacheKey = loadBundleId && (await hashQueryExecCall(api, loadBundleId));
    } catch (e) {
      reportError(e);
      // Fall back to not using the cache.
    }

    const actionProperties = {
      cacheKey,
      loadBundleId,
      dateFilters: dateFilters,
      meta: {
        api,
        forPreview,
        ...(forPreview && { chart: query }),
      },
      slice,
      visualizationId: query.visualizationId,
      chart: query,
    };

    // Check if 'runQueryOnFilters' is provided
    if (runQueryOnFilters) {
      // Get the checked filters from 'menuFilters' based on 'runQueryOnFilters'
      const checked = getRunQueryFilters(
        menuFilters,
        filters,
        runQueryOnFilters
      );

      // If there are checked filters, run the query; otherwise, execute query without making an API call
      checked.length
        ? await runQuery(true)
        : executeQueryWithoutApiCall(
            dispatch,
            actionProperties,
            forPreview,
            query
          );
    } else {
      // If 'runQueryOnFilters' is not provided, simply run the query
      await runQuery();
    }

    async function runQuery(dropRunQueryOnFilters) {
      if (dropRunQueryOnFilters) {
        dispatch({
          type: actionTypes.RUN_ON_QUERY_FILTERS_CHECKED,
          checked: false,
        });
      }
      // Look in previously executed results to see if this query was already
      // executed to just take its data results without having call the API.
      const state = getState();
      const blocks = cacheKey
        ? (state.layout.header ? state.layout.header.blocks : []).concat(
            state.dashboard ? state.dashboard.blocks : []
          )
        : [];
      const cachedResult = blocks
        .map((block) => block.charts)
        .flat()
        .find(
          (chart) =>
            !chart.loading && !chart.refreshing && chart.cacheKey === cacheKey
        );

      if (cachedResult) {
        // Use the cache.
        dispatch({
          type: actionTypes.EXECUTE_QUERY_SUCCESS,
          ...actionProperties,
          meta: {
            ...actionProperties.meta,
            // Do not call the API for this cached result.
            api: null,
          },
          results: {
            data: cachedResult.data,
            meta: cachedResult.meta,
          },
          cacheUsed: true,
        });
      } else {
        await dispatch({
          type: actionTypes.EXECUTE_QUERY_START,
          ...actionProperties,
        });
        if (currentAbortController.signal.aborted) {
          return;
        }
      }
    }

    if (remainingCharts && remainingCharts.length) {
      const param = !forHeaderBlocks
        ? [remainingCharts, loadBundleId]
        : remainingCharts;

      dispatch(nextFunction(param));
    }
  };
}

const checkForIgnoreCondition = (filters, ignoreMenuFilters) => {
  if (!filters) {
    return [];
  }

  return [filters]
    .flat()
    .filter((filter) => !ignoreMenuFilters.includes(filter.type));
};

const removeDuplicates = (filters) => {
  return filters.filter(
    (filter, index, self) =>
      index ===
      self.findIndex(
        (selfFilter) =>
          selfFilter.type === filter.type && selfFilter.value === filter.value
      )
  );
};

export const loadRowExpandChart =
  (filters, query, exportAll) => (dispatch, getState) => {
    if (query.localRowExpandGrouping) {
      return;
    }

    const nonIgnoreFilters = checkForIgnoreCondition(
      filters,
      query.ignoreMenuFilters || []
    );

    const globalFilters = (query.filters || []).filter(
      // We use this parameter in the Power Editor to eliminate filters
      // that users have clicked on within the drilldown hierarchy.
      // This parameter will be automatically removed during the Power Editor validation on save.
      // However, it is necessary for us to manually remove it from the filters array when combining drilldown hierarchy and row expand functionality.
      (filter) => !filter.removeOnSave
    );

    const realFilters = removeDuplicates([
      ...globalFilters,
      ...nonIgnoreFilters,
    ]);

    const qs = queryBuilder({ ...query, filters: realFilters }, getState);
    const { convertGetToPost } = query;
    const dateFilters = getState().layout.dateFilters;

    const apiPrimary = {
      public: false,
      method: convertGetToPost ? "POST" : "GET",
      endpoint: `api/v1/queries/${query["queryId"]}/exec?${
        convertGetToPost ? "" : qs
      }`,
      reportAllErrors: true,
      ...(convertGetToPost && { payload: queryString.parse(qs) }),
    };

    const { loaded, data } =
      getState().dashboard.rowExpandedCharts[query.visualizationId] || {};

    dispatch({
      type: exportAll
        ? actionTypes.ROW_EXPAND_EXEC_QUERY_EXPORT_ALL_START
        : actionTypes.ROW_EXPAND_EXEC_QUERY_START,
      dateFilters: dateFilters,
      meta: {
        api: apiPrimary,
      },
      filters: realFilters,
      visualizationId: query.visualizationId,
      chart: { ...query, loaded, data: data || [] },
    });
  };

export const clearExportAllObject = () => (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_EXPORT_ALL_OBJECT });
};

export const initDashBoardLoader =
  (name, visualizationId) => (dispatch, getState) => {
    const loadedDashboard = getState().dashboard.name;
    const loadedHeader = getState().layout.header?.blocks || [];
    const blocks = getState().dashboard.blocks;
    const { filtersUpdateHeaderKPIs } = getState().layout;

    const allHeaderLoaded =
      loadedHeader.length > 0
        ? loadedHeader
            .map((block) => block.charts)
            .flat()
            .filter((chart) => chart.id && chart.type !== "Vertical Divider")
            .every((chart) => chart.loaded || chart.error || !chart.refreshing)
        : true;

    if (
      !allHeaderLoaded ||
      (!loadedHeader.length && !loadedDashboard) ||
      filtersUpdateHeaderKPIs
    ) {
      return dispatch(loadHeaderConfiguration([name, visualizationId]));
    }

    const tabHasChanged = loadedDashboard !== name;
    if (tabHasChanged && allHeaderLoaded) {
      return dispatch(loadDashboardConfiguration(name));
    }

    const anyNotLoaded = blocks.find((b) => b.charts.find((c) => !c.loaded));

    if (anyNotLoaded) {
      return dispatch(initLoadCharts(name));
    }
  };

export function loadDashboardConfiguration(
  name,
  visualizationId,
  configOnly,
  loadBundleId
) {
  return (dispatch, getState) => {
    const { layout } = getState();
    const page = activePageFromTabs();

    function activePageFromTabs() {
      return (
        layout.tabs.find((page) => page.slug === name) ||
        layout.tabs.find((page) => page) ||
        defaultPage
      );
    }

    const ax = axiosInstance;

    return performLoadDashboardConfiguration(
      page,
      layout.domain,
      layout.term,
      visualizationId,
      ax,
      dispatch,
      configOnly,
      loadBundleId
    ).then(() => {
      // do nothing
    });
  };
}

let loadDashboardConfigurationAbortController = new AbortController();

export async function performLoadDashboardConfiguration(
  page,
  domain,
  term,
  visualizationId,
  ax,
  dispatch,
  configOnly,
  loadBundleId
) {
  // Passing a visualization Id indicates we are on a detail screen with only a single vis
  const afterAction = visualizationId ? loadDetail : setChartVisibility;

  const endpoint = `api/v1/pages/${page.uuid}`;

  const type = actionTypes.LOAD_DASHBOARD_CONFIGURATION_SUCCESS;

  // Abort any previous request.
  loadDashboardConfigurationAbortController.abort();
  loadDashboardConfigurationAbortController = new AbortController();

  return ax
    .get(endpoint, {
      responseType: "json",
      signal: loadDashboardConfigurationAbortController.signal,
    })
    .then((res) => {
      if (!res.data) {
        // Not-found JSON configs requested from this FE project's public
        // directory may return 200 OK with HTML. Consider those not found.
        throw new Error(
          "The JSON configuration for the page could not be found."
        );
      }

      try {
        const results = normalizeResponse(res.data);

        const result = dispatch({
          type,
          term,
          results,
        });

        if (!configOnly) {
          if (afterAction === loadDetail) {
            dispatch(loadDetail(visualizationId));
          } else if (afterAction === setChartVisibility) {
            dispatch(setChartVisibility(loadBundleId));
          } else {
            dispatch(afterAction());
          }
        }

        return result;
      } catch (e) {
        // This error only writes to log now, is not visible to user
        handleError(e);
      }
    })
    .catch((err) => {
      handleError(err);
      if (axios.isCancel(err)) {
        return;
      }
      dispatch({
        type: actionTypes.LOAD_DASHBOARD_CONFIGURATION_FAIL,
        message: "Configuration not available",
      });
    });
}

export function normalizeResponse(response, notHide) {
  if (!response) return response;
  if (!response.data.blocks)
    throw new Error(
      "Something went wrong. Blocks must be defined. Please reload."
    );

  return {
    ...response.data,
    blocks: response.data.blocks
      // api using hide not hidden property, response come from api its not come from reducer where we already changed this hide to hidden
      .filter((block) => notHide || !block.hide)
      .map((block) => {
        const { settings } =
          block.visualizations.find((v) => Array.isArray(v.settings)) ?? {};

        return {
          ...block,
          segmentTitle: block.styles?.segmentTitle ?? "",
          charts: settings ?? block.visualizations.map(normalizeVisualization),
          visualizations: undefined,
        };
      }),
  };
}

function normalizeVisualization(vis) {
  const flatVis = { ...vis, ...vis.settings };
  return produce(flatVis, (draftVis) => {
    draftVis.queryId = draftVis.query?.uuid;
    draftVis.visualizationId = draftVis.uuid;
    delete draftVis.query;
    delete draftVis.settings;
  });
}

export const loadDetail = (visualizationId) => (dispatch, getState) => {
  const query = getState().dashboard.blocks.reduce((acc, curr, i) => {
    return (
      curr.charts.find((c) => c.visualizationId === visualizationId) || acc
    );
  }, {});

  if (query.visualizationId) {
    dispatch(executeQuery(query));
  }
};

export const setChartVisibility =
  (loadBundleId, visualizationId) => (dispatch, getState) => {
    const { menuFilters } = getState().layout;
    const ret = dispatch({
      type: actionTypes.SET_CHART_VISIBILITY,
      filters: menuFilters.map((item) => item.values).flat(),
    });

    dispatch(initLoadCharts(visualizationId, loadBundleId));

    return ret;
  };

export function initLoadCharts(visualizationUuid, loadBundleId = uuid()) {
  return (dispatch, getState) => {
    const charts = justCharts(getState)
      .filter((chart) =>
        visualizationUuid ? chart.uuid === visualizationUuid : true
      )
      .filter((chart) => chart.type !== "Vertical Divider");

    dispatch({ type: actionTypes.SET_LOAD_BUNDLE, loadBundleId, charts });
    dispatch(loadCharts([charts, loadBundleId]));
  };
}

export const loadCharts = (args) => (dispatch, getState) => {
  const [remainingCharts, loadBundleId] = args;

  // if loadBundleId doesn't match, stop
  // Short circuit execution id dashboard has changed
  const activeLoadBundleId = getState().dashboard.loadBundleId;
  if (activeLoadBundleId !== loadBundleId) return null;

  if (!remainingCharts.length) {
    return;
  }

  const [current, ...remaining] = remainingCharts;
  dispatch(
    executeQuery(current, remaining, loadCharts, "dashboard", loadBundleId)
  );
};

export const justCharts = (getState) => {
  const dashboard = getState().dashboard;
  const term = getState().layout.term;
  const menuFilters = getState().layout.menuFilters || [];
  const activeMenuFilters = menuFilters
    .filter((mf) => mf.values.find((v) => v.checked))
    .map((mf) => mf.values[0].key);

  return (dashboard?.blocks ?? [])
    .filter((b) => !b.section)
    .reduce((acc, curr) => [...acc, ...curr.charts], [])
    .filter((c) => c.visualizationId)
    .filter(
      (c) => !c.availableForTerms || c.availableForTerms.find((d) => d === term)
    )
    .filter((c) => c.type !== "ActiveTable")
    .filter(
      (c) =>
        !c.hideOnFilters ||
        !c.hideOnFilters.find((f) => activeMenuFilters.find((m) => m === f))
    );
};

// Detail charts
export const initDetails = (id) => (dispatch, getState) => {
  const matchedChart = nestedPullMatched(
    getState().dashboard.blocks,
    "charts",
    "visualizationId",
    id
  );
  if (!matchedChart) {
    loadDetail(id);
  }
};

function cloneQuery(getState, id) {
  const query = getState()
    .dashboard.blocks.map((block) => block.charts)
    .map((chart) => chart.find((item) => item.visualizationId === id))
    .filter((currentItem) => currentItem)[0];

  return omit(query, ["includeFullWeek", "limit", "orders", "overrides"]);
}

export const loadAllTableRecords =
  (id, showAll, page, dropPreviousData) => async (dispatch, getState) => {
    const copyQuery = cloneQuery(getState, id);

    if (!showAll) {
      dispatch({ type: actionTypes.CLEAR_ALL_TABLE_RECORDS });
    } else {
      if (copyQuery.alternateAllRecordsDateKey) {
        copyQuery.dateKey = copyQuery.alternateAllRecordsDateKey;
      }

      if (copyQuery.alternateAllRecordsQuery) {
        copyQuery.queryId = copyQuery.alternateAllRecordsQuery;
        copyQuery.isParameterized = false;
      }

      const qs = queryBuilder(copyQuery, getState, true);

      const queryId = copyQuery.queryId;

      dispatch({
        type: actionTypes.SHOW_ALL_TABLE_RECORDS_START,
        meta: {
          api: {
            method: "GET",
            endpoint: `api/v1/queries/${queryId}/exec?${qs}&perPage=${recordsPerPage}&page=${page}`,
            reportAllErrors: true,
            toastOnFailure: true,
          },
        },
        dropPreviousData,
      });
    }
  };

export const downloadTable =
  (format, id, showAll, headers = []) =>
  (dispatch, getState) => {
    const query = matchQueryFromStore();
    const { convertGetToPost } = query;

    const useAlternateQuery = query.alternateAllRecordsQuery && showAll;

    if (useAlternateQuery) {
      query.queryId = query.alternateAllRecordsQuery;
      query.isParameterized = false;
    }

    const showAllQuery = getShowAllQuery();

    const qs = queryBuilder(
      showAll ? omit(showAllQuery, ["limit"]) : omit(query, ["limit"]),
      getState,
      !!showAll,
      format
    );

    const endpoint = generateEndpoint();

    dispatch({
      type: actionTypes.DOWNLOAD_TABLE_DATA_START,
      meta: {
        api: {
          method: convertGetToPost ? "POST" : "GET",
          endpoint,
          file: true,
          format,
          reportAllErrors: true,
          fileName: generateName(),
          ...(convertGetToPost && {
            payload: queryString.parse(qs),
          }),
        },
      },
    });

    function matchQueryFromStore() {
      return getState()
        .dashboard.blocks.map((block) => block.charts)
        .map((chart) => chart.find((item) => item.visualizationId === id))
        .filter(Boolean)
        .map((chart) => ({
          ...chart,
          overrides: chart.overrides?.filter(
            (override) => !showAll && !override.mapping?.hideOnTables
          ),
        }))[0];
    }

    function generateName() {
      const { title, segmentTitle } = getState().dashboard.blocks.find(
        (item) => item.charts.find((c) => c.visualizationId === id) && item
      );
      return title || segmentTitle || "InsightOut Data";
    }

    function getShowAllQuery() {
      const overrides = headers.map((h) => ({ name: h }));

      return {
        ...query,
        ...(query.alternateAllRecordsDateKey && {
          dateKey: query.alternateAllRecordsDateKey,
        }),
        overrides,
        dataMerge: false,
      };
    }

    function generateEndpoint() {
      const hasOverrideQuery = showAll ? query.alternateAllRecordsQuery : false;
      const queryId = hasOverrideQuery || showAllQuery["queryId"];
      return `api/v1/queries/${queryId}/exec?${convertGetToPost ? "" : qs}`;
    }
  };

export const updateValues = (values) => () => {
  axiosInstance
    .post(`api/v1/queries/update-values`, { entries: values })
    .then(noop)
    .catch((err) => {
      console.error(err);
    });
};

export const cancelCalls = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.STOP_DASHBOARD_SYNC });
    loadDashboardConfigurationAbortController.abort();
  };
};

export const updateTablePagination =
  (chart, page, forPreview) => (dispatch) => {
    dispatch({ type: actionTypes.HANDLE_TABLE_PAGINATION, chart });
    dispatch(
      executeQuery(
        { ...chart, page, ignoreAbortSignal: true },
        null,
        null,
        "dashboard",
        null,
        forPreview
      )
    );
  };

export const removeExpandRowsOnExport =
  (
    ids,
    currentVisId,
    nonConvertPercentsBack,
    title,
    activeTab,
    dateFilters,
    filters
  ) =>
  (dispatch) => {
    dispatch({
      type: actionTypes.REMOVE_DEEPER_EXPANDED_ROWS,
      keys: ids,
      currentVisId,
      nonConvertPercentsBack,
      title,
      activeTab,
      dateFilters,
      filters,
    });
  };

export function clearBlocks() {
  return (dispatch) => {
    dispatch({ type: CLEAR_BLOCKS });
    // Abort any query execs.
    execAbortController.abort();
  };
}

export function clearPowerEditorPreview() {
  return (dispatch) => {
    dispatch({ type: actionTypes.REFRESH_POWER_EDITOR_PREVIEW });
  };
}

export const apiExport =
  (chart, allRecords = false, format = "excel") =>
  async (dispatch, getState) => {
    const query = allRecords ? omit(chart, "limit") : chart;
    const apiImageOverride = getApiImageFormatOverride(
      query.overrides,
      allRecords
    );

    // Initialize the query string
    let qs = "";

    // Check if there's an API image override mapping type
    if (apiImageOverride) {
      // Fetch query data from the API
      const response = await axiosInstance.get(
        `api/v1/queries/${query.queryId}`
      );

      // Generate overrides based on query fields and add api-image format
      const allRecordsOverrides = generateOverridesOnAllRecords(
        response.data,
        apiImageOverride,
        query.overrides
      );

      // Update the query string with overrides
      qs = queryBuilder(
        { ...query, overrides: allRecordsOverrides },
        getState,
        false,
        format
      );
    } else {
      // Use the original query string without overrides
      qs = queryBuilder(query, getState, allRecords, format);
    }

    const {
      convertGetToPost,
      queryId,
      exportFileName = "InsightOut Data",
    } = query;

    return dispatch({
      type: actionTypes.DOWNLOAD_TABLE_DATA_START,
      meta: {
        api: {
          method: convertGetToPost ? "POST" : "GET",
          endpoint: `api/v1/queries/${queryId}/exec?${
            convertGetToPost ? "" : qs
          }`,
          file: true,
          format,
          reportAllErrors: true,
          fileName: exportFileName,
          ...(convertGetToPost && {
            payload: queryString.parse(qs),
          }),
        },
      },
    });
  };

function getApiImageFormatOverride(overrides, allRecords) {
  if (!allRecords) {
    return;
  }

  return (overrides ?? []).find(
    (override) => override.mapping?.type === "api-image"
  );
}

export const apiExportAllDrilldownSubtitles =
  (chart, title, setExporting) => async (dispatch, getState) => {
    setExporting(true);
    // empty array for recursive accumulator
    const allOverrides = [];
    let format = "excel";

    function getOverridesRecursively(chart) {
      // Add subtitles if they exist
      if (chart.overrides) {
        allOverrides.push(...chart.overrides);
      }

      if (chart.exportFormat) {
        format = chart.exportFormat;
      }

      // Handle rowExpandVisualizationParams recursively
      const params = chart.rowExpandVisualizationParams;
      if (params) {
        if (chart.dynamicDrilldowns) {
          Object.values(params).forEach(getOverridesRecursively);
        } else {
          getOverridesRecursively(params);
        }
      }
    }

    getOverridesRecursively(chart);

    const qs = queryBuilder(
      omit(
        { ...chart, overrides: removeDuplicateOverride(allOverrides) },
        "limit"
      ),
      getState,
      null,
      format
    );

    await dispatch({
      type: actionTypes.DOWNLOAD_TABLE_DATA_START,
      meta: {
        api: {
          method: chart.convertGetToPost ? "POST" : "GET",
          endpoint: `api/v1/queries/${chart.queryId}/exec?${
            chart.convertGetToPost ? "" : qs
          }`,
          file: true,
          format,
          reportAllErrors: true,
          fileName: title,
          ...(chart.convertGetToPost && {
            payload: queryString.parse(qs),
          }),
        },
      },
    });

    setExporting(false);
  };

function sortFieldsByOverridesOrder(allFields, overrides) {
  function getFieldIndex(field) {
    const index = overrides.findIndex(
      (override) => override.name === field.name
    );
    return index < 0 ? allFields.length : index;
  }

  const indexed = allFields.map((field) => ({
    ...field,
    sortIndex: getFieldIndex(field),
  }));

  return orderBy(indexed, "sortIndex");
}

function generateOverridesOnAllRecords(query, override, overrides) {
  const allFields = query.data.dataSources
    .map((ds) => ds.fields)
    .flat()
    .map((field) =>
      field.name === override.name
        ? { name: field.name, mapping: { type: override.mapping.type } }
        : { name: field.name }
    );

  const ordered = sortFieldsByOverridesOrder(allFields, overrides);

  return ordered.map((field) => omit(field, "sortIndex"));
}

export function handleSnackbarErrors(error) {
  if (!error || !error.data) {
    return;
  }

  return Object.values(error.data).map((error) => error[0]);
}

export const setRowExpandedChartsHeight = (index, height) => (dispatch) => {
  dispatch({ type: actionTypes.SET_ROW_EXPANDED_CHARTS_HEIGHT, index, height });
};
