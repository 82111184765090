import React, { useState, useMemo, useEffect } from "react";
import styled from "@emotion/styled";
import ChartContainer from "../BaseChart/ChartContainer";
import TableView from "./TableView";
import tableMapper, { setParentHeadersMapping } from "./functions/tableMapper";
import Details from "../../Layout/Details/Details";
import { orderSpecialTypes } from "../../utils/func";
import validateTableConfig from "./validateTableConfig";
import TableVisualizationCollection, {
  removeExcessRows,
} from "./TableVisualizationCollection";
import RoundingSelector from "./RoundingSelector";
import { cloneDeep } from "lodash-es";
import { getDateDifference } from "../../store/actions/queryBuilder/getLatestDate";
import { sortByKey } from "../../utils/dates/dateSorting";
import StickyHeader from "./Elements/StickyHeader";
import { WriteBacksProvider, WRITE_BACKS_TYPES } from "./WriteBacksContext";
import Flex from "../../UI/Flex/Flex";
import GrouppedColumnsSelection from "./TableColumnsSelection/GrouppedColumnsSelection";
import SimpleColumnsSelection from "./TableColumnsSelection/SimpleColumnsSelection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tipper from "../../UI/Tipper/Tipper";
import DrilldownInModal from "./Elements/DrilldownInModal";
import { postMessageFromIframe } from "./functions/dynamicDrillDown";
import {
  getSortDirection,
  sortDynamicGroupingValues,
} from "./functions/dynamicSorting";
import { getComparisonModeItem } from "../../Layout/Block/interruptDashboard";
import { withCalculatedHorizontalTotals } from "./tableTotal";
import {
  overrideMetaFields,
  setMultipleColumn,
} from "./functions/tableCellHelper";
import { excludedTypesFromTotals } from "../../utils/constants/constants";

const TitleContainer = styled(Flex)`
  margin-bottom: ${(props) => (props.margin ? "1rem" : 0)};
  margin-left: 10px;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 500;
  display: flex;
`;

const ShowHideColumnsIcon = styled(FontAwesomeIcon)(
  ({ theme }) => `
  font-weight: 500;
  cursor: pointer;

  &:hover {
    color: ${theme.primary}
  }
`
);

export default function TableVisualization(props) {
  const {
    chart,
    details,
    dashboardId,
    canEdit,
    dateFilters,
    sortPaginated,
    sticky,
    setSticky,
    fromFullDetails,
    menuFilters,
    comparisonMode,
    activeTab,
    offsetTop,
    title: blockTitle,
    limit,
    setLimit,
    rowsQuantity,
    domain,
    isLoading,
    loadMoreConfig,
    search,
    setSearch,
  } = props;

  const [dynamicSortedRows, setDynamicSortedRows] = useState(null);

  const {
    data: sourceData,
    loading = true,
    refreshing,
    error,
    visualizationId,
    subTitles = [],
    rowGroupKey,
    groupingKey,
    prefix,
    uniqueValueKey,
    staticGroupingKeys,
    calculatedRows,
    isSortable,
    rollup,
    hideExportToExcel,
    totals,
    regularFormatting,
    rawRows,
    roundingSelector,
    renderAsImageKeys,
    totalsOnly,
    totalsAverages,
    latestDate,
    specialSortingTypes,
    staticHighLevelKeys,
    dynamicHighLevelKeys,
    hideDetailLink,
    pinnedRows,
    groupingDataConfig,
    subTotalRow,
    totalsPositionTop,
    title,
    restricted,
    hideHeaders,
    hideDetails,
    offsetZebra,
    groupingKeyExcessItems,
    nonConvertPercentsBack,
    groupingKeyMapping,
    totalsCharts,
    fixedHeight,
    initialOrderConfig,
    groupingExtraColumns,
    rawRowsValueKey,
    totalColumnsStatic,
    excludeRowFromTotalCalculation,
    stickyHeaders,
    totalsOverride,
    fiscalQuarterStartOffset,
    writeBacks,
    dynamicSubTitleKeys,
    dynamicPostfixColumn,
    displayRows,
    connectWithQueryParameter,
    showHideColumns,
    noResultsMessageOverride,
    globalStickyHeaders,
    groupByKey,
    headerColorConfig,
    noTotalCalcKeys = [],
    horizontalTotals,
    multipleRowGroups,
    multipleRowGroupsDisplayName,
    weekOffsetKey,
    ...restChart
  } = chart;

  const [sortKey, setSortKey] = useState(null);
  const [sortUniqueKey, setSortUniqueKey] = useState(null);
  const [rowIndexes, setRowIndexes] = useState([]);
  const [rowData, setRowData] = useState(null);
  const [sortOrder, setSortOrder] = useState("DESC");
  const [showColumnsSidebar, setShowColumnsSidebar] = useState(false);
  const [drilldownLevel, setDrilldownLevel] = useState({});
  const [parentHeaderSort, setParentHeaderSort] = useState({
    header: null,
    direction: null,
  });

  const [hiddenGroupings, setHiddenGroupings] = useState(
    chart.hiddenGroupings || []
  );
  const [hiddenColumns, setHiddenColumns] = useState(chart.hiddenColumns || []);

  const [commentCellData, setCommentCellData] = useState(null);

  useEffect(() => {
    if (chart?.hiddenGroupings) {
      setHiddenGroupings(chart.hiddenGroupings);
    }

    if (chart?.hiddenColumns) {
      setHiddenColumns(chart.hiddenColumns);
    }
  }, [chart?.hiddenGroupings, chart?.hiddenColumns]);

  const [performRounding, setPerformRounding] = useState(!!roundingSelector);
  const configCopy = cloneDeep(chart);

  // ignore strings and some columns in noTotalCalcKeys from total calculaton
  const noCalcKeys = [
    ...noTotalCalcKeys,
    ...(chart?.meta?.fields || [])
      .filter(
        (f) =>
          excludedTypesFromTotals?.includes(f.type) || f.type?.includes("date")
      )
      .map((k) => k.name),
  ];

  const source = cloneDeep(
    removeExcessRows(sourceData, groupingKeyExcessItems, groupingKey)
  );

  let data = source
    .map((item) =>
      setParentHeadersMapping(
        item,
        groupingKeyMapping,
        groupingKey,
        fiscalQuarterStartOffset
      )
    )
    // Possibility to have more than one column on repeating groups/ group columns
    .map((item) => setMultipleColumn(chart, item))
    .filter((_, index) => {
      if (connectWithQueryParameter || groupingKey) {
        return true;
      }
      return displayRows ? index < +limit : true;
    });

  validateTableConfig(chart);

  const rowMainKey = defineMainKey();
  const sortedData = sortData();
  const emptyFirstColumnHeader = !groupingKey && !calculatedRows;

  const comparisonModeItem = getComparisonModeItem(
    comparisonMode,
    activeTab?.uuid
  );

  const aTableCollection = useMemo(
    () =>
      new TableVisualizationCollection(
        data,
        sortedData,
        groupingKey,
        rowGroupKey,
        restChart.rowGroupKeys,
        restChart.filterNulls,
        weekOffsetKey
      ),
    [
      data,
      sortedData,
      groupingKey,
      rowGroupKey,
      restChart.rowGroupKeys,
      restChart.filterNulls,
      weekOffsetKey,
    ]
  );

  if (dynamicHighLevelKeys) {
    aTableCollection.setDynamicHighLevelHeaders(
      dynamicHighLevelKeys,
      menuFilters,
      comparisonModeItem
    );
  }

  if (staticHighLevelKeys) {
    aTableCollection.setHighLevelHeaders(staticHighLevelKeys);
  }

  aTableCollection.setSubTitles(
    subTitles,
    null,
    null,
    null,
    restChart.overrides,
    rawRows,
    restChart.hiddenNullableColumns
  );

  aTableCollection.setHeaders(
    emptyFirstColumnHeader,
    rowMainKey,
    staticGroupingKeys,
    prefix,
    groupingKey,
    groupingKeyMapping
  );

  if (rollup) {
    aTableCollection.setRollupData(rollup, data);
    aTableCollection.setRollupSubTitles(rollup, data);
  }

  tableMapper(
    aTableCollection,
    rowMainKey,
    groupingKey,
    uniqueValueKey,
    emptyFirstColumnHeader,
    totals,
    totalsAverages,
    pinnedRows,
    groupingDataConfig,
    subTotalRow,
    totalsPositionTop,
    totalsCharts,
    groupingExtraColumns,
    rawRowsValueKey,
    totalColumnsStatic,
    excludeRowFromTotalCalculation,
    totalsOverride,
    restChart.progressBarsSettings,
    noCalcKeys,
    restChart.rowGroupKeys,
    restChart.totalLabel,
    restChart.distinctTotalsCounts
  );

  // for dynamic Sub Titles we need to update all subtitles based on dynamic data
  if (dynamicSubTitleKeys) {
    aTableCollection.setSubTitles(
      subTitles,
      dynamicSubTitleKeys,
      groupingKeyMapping,
      dynamicPostfixColumn
    );
  }

  function defineMainKey() {
    // migrate to factory for Table Classes
    if (rollup) return "rowValue";
    if (rawRows) return null;

    function firstKeyAvailable() {
      return Object.keys(data[0])[0];
    }
    return !rowGroupKey && data?.length ? firstKeyAvailable() : rowGroupKey;
  }

  function sortData() {
    if (sortPaginated || groupingKey) {
      return data;
    }

    if (initialOrderConfig) {
      const { column, order, type } = initialOrderConfig;
      return orderSpecialTypes(data, column, order, type);
    }

    return isSortable && sortKey
      ? sortByKey(
          sortKey,
          sortOrder,
          regularFormatting,
          specialSortingTypes,
          chart.formatOverrides,
          chart.coloredColumnEdges,
          rowGroupKey
        )([...data])
      : data;
  }

  // for grouping tables original source data length is different then output data length
  function limitGroupingKeyStructuredRows(rows) {
    return rows.filter((_, index) => (displayRows ? index < +limit : true));
  }

  const {
    highLevelHeaders,
    headers,
    rows,
    progressBarTotals,
    postfixes,
    columnKeys,
  } = aTableCollection;

  const preparedDataForTableView = {
    highLevelHeaders,
    progressBarTotals,
    postfixes,
    ...withCalculatedHorizontalTotals({
      headers,
      rows: limitGroupingKeyStructuredRows(dynamicSortedRows || rows),
      columnKeys,
      horizontalTotals,
      // When there are multiple group keys, concatenate them into a single rowGroupKey and use it as the field name.
      // This concatenated rowGroupKey will then be added to the meta fields with the specified display name.
      meta: overrideMetaFields(chart),
      groupingKey,
    }),
  };

  // drop local state sorted data on source data change
  useEffect(() => {
    if (sourceData && rowGroupKey) {
      setDynamicSortedRows(null);
      setParentHeaderSort({ header: null, direction: null });
    }
  }, [sourceData, rowGroupKey]);

  const sortDynamicGrouping = (value, direction, dynamicSubTitleKey) => {
    const sortedRows = sortDynamicGroupingValues(
      rows,
      dynamicSubTitleKey ?? dynamicSubTitleKeys[0],
      groupingKey,
      value,
      direction,
      pinnedRows,
      groupingKeyMapping,
      totals,
      weekOffsetKey
    );

    setDynamicSortedRows(sortedRows);
  };

  const handleSort = (item, key, index) => {
    const direction = getSortDirection(sortKey, item, chart.meta, sortOrder);
    setSortOrder(direction);
    setSortUniqueKey(key);
    setSortKey(item);

    if (groupingKey) {
      sortDynamicGrouping(headers[index], direction, item);
    }

    if (sortPaginated) {
      sortPaginated(item, direction);
    }
  };

  if (totalsOnly) {
    const key = uniqueValueKey || groupByKey || rowMainKey;
    const totalRow = preparedDataForTableView.rows.filter((row) =>
      (row.values ?? []).find((v) => v[key] === "Total")
    );

    preparedDataForTableView.rows = totalRow;
  }

  const hasData = getDateDifference(latestDate, dateFilters);

  const showDetails =
    hasData && details && chart.data.length > 0 && !hideDetails;

  const hasChartData = () => {
    if (hasData && data) {
      const condition =
        chart.writeBacks?.type === WRITE_BACKS_TYPES.crm ||
        chart.writeBacks === WRITE_BACKS_TYPES.crm;

      return !!(data.length || condition);
    }

    return false;
  };

  const onZoomClose = () => {
    setRowData(null);
    if (chart.zoomInDrilldownConfig.attachedURLParameter) {
      postMessageFromIframe(chart.zoomInDrilldownConfig.attachedURLParameter);
    }
  };

  const quantity = rowGroupKey ? rows.length : rowsQuantity;

  return (
    <>
      <TitleContainer margin={showHideColumns}>
        <Title>{title}</Title>
        {showHideColumns && (
          <Tipper label="Show/Hide columns">
            <ShowHideColumnsIcon
              icon={["far", "columns"]}
              onClick={() => setShowColumnsSidebar(true)}
            />
          </Tipper>
        )}
      </TitleContainer>
      <div
        id={visualizationId}
        style={{ position: "relative", width: props.width }}
      >
        <StickyHeader
          sticky={sticky}
          setSticky={setSticky}
          stickyHeaders={stickyHeaders}
          loading={loading}
          limit={limit}
          displayRows={displayRows}
          setLimit={setLimit}
          rowsQuantity={quantity}
          selectedMenuFilter={chart.selectedMenuFilter}
          search={search}
          setSearch={setSearch}
          chart={chart}
        />
        <ChartContainer
          autoHeight
          hasData={hasChartData()}
          loading={loading}
          refreshing={refreshing}
          error={error}
          restricted={restricted}
          fixedHeight={fixedHeight}
          noResultsMessageOverride={noResultsMessageOverride}
          width={props.width}
          runQueryOnFiltersMessage={chart.runQueryOnFiltersMessage}
        >
          <WriteBacksProvider
            configInput={writeBacks}
            queryUuid={
              writeBacks && writeBacks.querySaveId
                ? writeBacks.querySaveId
                : chart.queryId
            }
          >
            <TableView
              {...restChart}
              data={preparedDataForTableView}
              rowGroupKey={rowMainKey}
              groupingKey={groupingKey}
              subTitles={preparedDataForTableView.columnKeys}
              staticGroupingKeys={staticGroupingKeys}
              valueKeyAsSubTitle={!groupingKey && !calculatedRows}
              calculatedRows={calculatedRows}
              isSortable={isSortable}
              setSortKey={handleSort}
              sortKey={sortUniqueKey}
              sortOrder={sortOrder}
              rollup={rollup}
              canEdit={canEdit}
              exportId={chart.exportId || visualizationId}
              totals={totals}
              performRounding={performRounding}
              regularFormatting={regularFormatting}
              renderAsImageKeys={renderAsImageKeys}
              chart={configCopy}
              pinnedRows={pinnedRows}
              rowIndexes={rowIndexes}
              setRowIndexes={setRowIndexes}
              subTotalRow={subTotalRow}
              totalsPositionTop={totalsPositionTop}
              hideHeaders={hideHeaders}
              offsetZebra={offsetZebra}
              segmentTitle={props.segmentTitle}
              groupingExtraColumns={groupingExtraColumns}
              stickyHeaders={globalStickyHeaders ?? sticky}
              fromFullDetails={fromFullDetails}
              activeTab={activeTab}
              dateFilters={dateFilters}
              menuFilters={menuFilters}
              groupingKeyMapping={groupingKeyMapping}
              offsetTop={globalStickyHeaders ? offsetTop : 0}
              title={title || blockTitle}
              setRowData={setRowData}
              rowData={rowData}
              hiddenGroupings={hiddenGroupings}
              hiddenColumns={hiddenColumns}
              dynamicSubTitleKeys={dynamicSubTitleKeys}
              sortDynamicGrouping={sortDynamicGrouping}
              headerColorConfig={headerColorConfig}
              drilldownLevel={drilldownLevel}
              setDrilldownLevel={setDrilldownLevel}
              rowMainKey={rowMainKey}
              noTotalCalcKeys={noTotalCalcKeys}
              isLoading={isLoading}
              parentHeaderSort={parentHeaderSort}
              setParentHeaderSort={setParentHeaderSort}
              meta={preparedDataForTableView.meta}
              horizontalTotals={horizontalTotals}
              commentCellData={commentCellData}
              setCommentCellData={setCommentCellData}
            />
          </WriteBacksProvider>

          <RoundingSelector
            show={roundingSelector}
            active={performRounding}
            setActive={setPerformRounding}
          />
        </ChartContainer>
      </div>
      {showDetails && (
        <div style={{ marginBottom: 12 }}>
          <Details
            dashboardName={dashboardId}
            visualizationId={visualizationId}
            exportId={hideExportToExcel ? null : visualizationId}
            hideDetailLink={hideDetailLink}
            rowIndexes={rowIndexes}
            setRowIndexes={setRowIndexes}
            nonConvertPercentsBack={nonConvertPercentsBack}
            title={title || props.title || props.segmentTitle}
            chart={chart}
            activeTab={activeTab}
            dateFilters={dateFilters}
            menuFilters={menuFilters}
            isTableVisualization
            domain={domain}
            loadMoreConfig={loadMoreConfig}
            isDrillable={restChart.rowExpandVisualizationParams}
          />
        </div>
      )}

      {rowData && (
        <DrilldownInModal
          rowData={rowData}
          onClose={onZoomClose}
          chart={{
            ...chart.zoomInDrilldownConfig,
            data: rowData,
            meta: preparedDataForTableView.meta,
          }}
        />
      )}

      {preparedDataForTableView.headers?.length > 0 ? (
        <GrouppedColumnsSelection
          isOpened={showColumnsSidebar}
          meta={preparedDataForTableView.meta}
          allGroups={preparedDataForTableView.headers}
          hiddenGroupings={hiddenGroupings}
          allColumns={aTableCollection.columnKeys}
          hiddenColumns={hiddenColumns}
          onClose={() => setShowColumnsSidebar(false)}
          hiddenGroupsChange={(headers) => setHiddenGroupings(headers)}
          hiddenColumnsChange={(columns) => setHiddenColumns(columns)}
        />
      ) : (
        <SimpleColumnsSelection
          isOpened={showColumnsSidebar}
          meta={preparedDataForTableView.meta}
          allColumns={aTableCollection.columnKeys}
          hiddenColumns={hiddenColumns}
          onClose={() => setShowColumnsSidebar(false)}
          hiddenColumnsChange={(columns) => setHiddenColumns(columns)}
        />
      )}
    </>
  );
}
