import React, { useEffect, useRef, useState, useCallback } from "react";
import styled from "@emotion/styled";
import { useHistory, useLocation } from "react-router-dom";
import LeftMenu from "./LeftMenu/LeftMenu";
import * as actions from "../store/actions/index";
import { shallowEqual, useDispatch } from "react-redux";
import useOnClickOutside from "../hooks/useOnClickOutside";
import mobileBreakpoints from "../styles/mobileBreakpoints";
import EnterMenu from "./LeftMenu/EnterMenu";
import { useMediaQuery } from "react-responsive";
import { setDateFilters } from "../utils/formatters/dateFormatter";
import {
  DATE_FILTER_MODE_CUSTOM,
  dateFiltersTypeDependency,
  dateFiltersPageDependency,
} from "../utils/constants/constants";
import LeftMenuHider from "./LeftMenuHider";
import { useShallowEqualSelector } from "../store";
import { setCustomDateHandler } from "./LeftMenu/ToDateFilters/customDateUtils";
import DateFilterConfig from "./LeftMenu/DateFilterConfig";
import { getStartEndDate } from "./LeftMenu/ToDateFilters/getStartEndDate";
import usePrevious from "../utils/usePrevious";

const Container = styled.div(
  ({ theme, menuVisible }) => `
    background: ${
      theme.menuPrimary ? theme.primary : theme.background.secondary
    };
    color: ${theme.text.secondary};
    display: flex;
    margin-top: -36px; // height of menu hidden component
    flex-direction: column;
    padding-right: 2px;
    font-size: 14px;
    width: 250px;
    flex-grow: 0;
    flex-shrink: 0;
    position: relative;
    left: ${menuVisible ? 0 : -250}px;
    transition: .3s ease left;
    z-index: 1003;
    a {
        text-decoration: none;
        color: ${theme.text.secondary};
    }
    
    @media (max-width: ${mobileBreakpoints.mobile}) {
      height: 100vh;
      position: absolute;
      top: 0;
      left: 0;
      width: 300px;
      padding-top: 0;
      overflow: auto;
    }
`
);

export default function LeftMenuContainer(props) {
  const {
    show,
    setShow,
    hidden,
    menuVisible,
    setMenuVisible,
    selectPowerEditorModeFilter,
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
  const location = useLocation();

  // Check if the link contains "/details/" to determine if it's a details page
  const link = location.pathname;
  const fromDetails = link.includes("/details/");

  // If it's a details page, extract the last segment of the URL as the visualization ID
  const visualizationId = fromDetails ? link.split("/").pop() : null;

  const {
    dateFiltersConfig,
    logo,
    tabs,
    dateFilters,
    menuFilters,
    booleanFilters,
    term,
    domain,
    activeTab,
  } = useShallowEqualSelector(
    (state) => ({
      dateFilters: state.layout.dateFilters,
      dateFiltersConfig: state.layout.dateFiltersConfig,
      logo: state.layout.logo,
      tabs: state.layout.tabs,
      menuFilters: state.layout.menuFilters,
      booleanFilters: state.layout.booleanFilters,
      term: state.layout.term, // we are using this from redux, but not from Object.
      domain: state.layout.domain,
      activeTab: state.layout.activeTab,
    }),
    shallowEqual
  );

  const dualType = dateFiltersConfig?.filterCustomValues?.find((fcv) =>
    dateFiltersTypeDependency.includes(fcv)
  );
  const dualPage = dateFiltersPageDependency.includes(
    location.pathname.split("/").pop()
  );

  const isFilterModeCustom =
    dateFiltersConfig?.filterMode === DATE_FILTER_MODE_CUSTOM;

  const [selectedDateType, setSelectedDateType] = useState(null);

  const tabSlug = history.location.pathname.split("/")[2];

  const currentTabId =
    tabs.find((tab) => tab.slug === tabSlug)?.uuid ?? activeTab?.uuid;

  const toDate = dateFiltersConfig && isFilterModeCustom;

  const prevTabId = usePrevious(currentTabId);
  const tabChanged = prevTabId !== currentTabId;

  const execDateFilters = useCallback(
    (from, to, dateType) => {
      setSelectedDateType(dateType);

      dispatch(
        actions.setDateFilters(
          {
            start: { type: dateFiltersConfig.fieldName, value: from },
            end: { type: dateFiltersConfig.fieldName, value: to },
            // term: dateFiltersConfig.rangeSettingsTerm, // what is this
          },
          dateType,
          !selectPowerEditorModeFilter
        )
      );

      // call update chart preview if in power editor mode
      if (selectPowerEditorModeFilter) {
        selectPowerEditorModeFilter();
      }
    },
    [dateFiltersConfig.fieldName, dispatch, selectPowerEditorModeFilter]
  );

  const onDateTypeChangeHandler = useCallback(
    (term, option) => {
      // Before executing the date range change: let's make sure that this custom
      // date filter is one that does not require you to fill out any additional
      // input field.

      const type = term.value;

      const { from, to } =
        option ??
        setDateFilters(
          domain,
          type,
          0,
          undefined,
          undefined,
          dateFiltersConfig,
          dateFilters
        );
      execDateFilters(from, to, term);
    },
    [dateFilters, domain, execDateFilters, dateFiltersConfig]
  );

  // To fire when available date types have changed, including first time
  useEffect(() => {
    if (!selectedDateType || tabChanged) {
      setCustomDateHandler(
        isFilterModeCustom,
        dateFiltersConfig,
        setSelectedDateType, // Set State Hook
        currentTabId,
        onDateTypeChangeHandler,
        selectedDateType
      );
    }
  }, [
    currentTabId,
    dateFiltersConfig,
    isFilterModeCustom,
    onDateTypeChangeHandler,
    selectedDateType,
    tabChanged,
  ]);

  useEffect(() => {
    dispatch(actions.setCurrentDateFilterTerm(selectedDateType));
  }, [selectedDateType, dispatch]);

  const onDateChangeHandler = (start, end) => {
    const { from, to } = setDateFilters(
      domain,
      dateFiltersConfig.term || dateFiltersConfig.rangeSettingsTerm,
      0,
      start,
      end
    );
    execDateFilters(from, to, selectedDateType);
  };

  const onDateTermHandler = (option) => {
    dispatch(actions.setDateTerm(option.value));
  };

  const node = useRef();
  useOnClickOutside(node, () => {
    if (setShow) {
      setShow(false);
    }
  });

  const { startDate, endDate } = getStartEndDate(
    dateFiltersConfig,
    dateFilters,
    isFilterModeCustom,
    dualPage,
    dualType
  );

  const select = useCallback(
    (filter, type, shouldReloadCharts, options = {}) => {
      const updateCharts = selectPowerEditorModeFilter
        ? false
        : shouldReloadCharts;

      dispatch(
        actions.selectFilter(
          filter,
          type,
          updateCharts,
          options,
          visualizationId // reload only current chart from full details
        )
      );

      // call update chart preview if in power editor mode
      if (selectPowerEditorModeFilter) {
        selectPowerEditorModeFilter();
      }
    },
    [dispatch, selectPowerEditorModeFilter, visualizationId]
  );

  const selectBoolean = (filter) => {
    dispatch(
      actions.selectFilter(filter, "boolean", !selectPowerEditorModeFilter)
    );
  };

  const recentMonthsChange = (option, innerDateType) => {
    const type = innerDateType ?? selectedDateType;
    onDateTypeChangeHandler(type, option);
  };

  const dateConfig = new DateFilterConfig(
    dateFiltersConfig,
    onDateTermHandler,
    term,
    onDateChangeHandler,
    startDate,
    endDate,
    toDate,
    setSelectedDateType,
    selectedDateType,
    recentMonthsChange,
    execDateFilters,
    onDateTypeChangeHandler
  );

  // do not show menu hider when open from power editor mode
  const showHider = !selectPowerEditorModeFilter && !mobileBreakpoints.isMobile;

  const menu = (
    <Container ref={node} menuVisible={menuVisible}>
      {showHider && (
        <LeftMenuHider
          menuVisible={menuVisible}
          handleClick={() => setMenuVisible((val) => !val)}
        />
      )}
      <LeftMenu
        filters={menuFilters}
        booleanFilters={booleanFilters}
        hide={() => dispatch(actions.hideSubmenu())}
        select={select}
        selectBoolean={selectBoolean}
        dateFiltersConfig={dateFiltersConfig}
        handleLogout={() => history.push("/logout")}
        logo={logo}
        setShow={setShow}
        term={term}
        domain={domain}
        currentTabId={currentTabId}
        toDate={toDate}
        recentMonthsChange={recentMonthsChange}
        hidden={hidden}
        dualPage={dualPage}
        dualType={dualType}
        dateConfig={dateConfig}
        selectPowerEditorModeFilter={selectPowerEditorModeFilter}
      />
    </Container>
  );

  return isMobile ? <EnterMenu show={show}>{menu}</EnterMenu> : menu;
}
