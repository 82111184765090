import * as actionTypes from "../actionTypes";
import axios from "../../../axios";
import queryBuilder from "../queryBuilder/queryBuilder";

export const getCommentsQueryTable =
  (commentCellData) => async (dispatch, getState) => {
    try {
      dispatch({ type: actionTypes.GET_COMMENTS_TABLE_START });

      let query = null;

      const res = await axios.get(`/api/v1/connection-comments/table`);

      query = res.data?.data;

      if (!res.data?.data || res.data.data.length === 0) {
        const created = await axios.post(`/api/v1/connection-comments/table`);

        query = created.data.data;
      }

      const chart = {
        overrides: [],
        queryId: query.uuid,
        allDates: true,
        filters: [
          { type: "io_row_uuid", value: commentCellData.io_row_uuid },
          { type: "io_deleted_at", value: null },
        ],
      };

      const qs = queryBuilder(chart, getState);

      const exec = await axios.get(
        `api/v1/queries/${chart.queryId}/exec?${qs}`
      );

      dispatch({
        type: actionTypes.GET_COMMENTS_TABLE_SUCCESS,
        data: exec.data?.data ?? [],
      });
    } catch (err) {
      dispatch({ type: actionTypes.GET_COMMENTS_TABLE_FAIL });
    }
  };

export const createUpdateComment = (params) => (dispatch) => {
  const { comment, tbl, field, rowUuid, commentUuid } = params;

  dispatch({
    type: commentUuid
      ? actionTypes.UPDATE_COMMENTS_START
      : actionTypes.CREATE_COMMENTS_START,
    meta: {
      api: {
        method: commentUuid ? "PUT" : "POST",
        endpoint: `/api/v1/connection-comments/${commentUuid ?? ""}`,
        payload: {
          comment,
          tbl,
          field,
          io_row_uuid: rowUuid,
        },
        toastOnFailure: true,
      },
      toasts: [
        {
          type: "success",
          title: "Comment!",
          message: `Comment ${commentUuid ? "updated" : "added"}.`,
          condition: commentUuid
            ? actionTypes.UPDATE_COMMENTS_SUCCESS
            : actionTypes.CREATE_COMMENTS_SUCCESS,
        },
      ],
    },
  });
};

export const deleteComment =
  (commentUuid, setCommentItem) => async (dispatch) => {
    await dispatch({
      type: actionTypes.DELETE_COMMENTS_START,
      commentUuid,
      meta: {
        api: {
          method: "DELETE",
          endpoint: `/api/v1/connection-comments/${commentUuid}`,
          toastOnFailure: true,
        },
        toasts: [
          {
            type: "success",
            title: "Automation!",
            message: "Schedule set successfully",
            condition: actionTypes.DELETE_COMMENTS_START,
          },
        ],
      },
    });

    setCommentItem(null);
  };
