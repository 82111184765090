import { useEffect, useState } from "react";
import DatePickerContainer from "../../../UI/DatePicker/DatePickerContainer";
import { format, parseISO, subDays } from "date-fns";
import { dateConfig } from "../../../utils/formatters/dateFormatter";

const date = parseISO(dateConfig.defaultDateString);
const yesterday = format(subDays(date, 1), "yyyy-MM-dd");

export default function SingleDay({ Container, recentMonthsChange, theme }) {
  const [singleDay, setSingleDay] = useState("");

  useEffect(() => {
    if (!singleDay) {
      setSingleDay(yesterday);
      recentMonthsChange({ from: yesterday, to: yesterday });
    }
  }, [recentMonthsChange, singleDay]);

  return (
    <Container>
      <DatePickerContainer menuPrimary={theme.menuPrimary}>
        <input
          type="date"
          onChange={(e) => {
            setSingleDay(e.target.value);
            recentMonthsChange({ from: e.target.value, to: e.target.value });
          }}
          value={singleDay}
          data-cy="single-day-input"
        />
      </DatePickerContainer>
    </Container>
  );
}
